import { useAuth } from "../../providers/auth";
import ViewClient from "../viewClient";
import ViewEmployee from "../viewEmployee";
import Documents from "./documents";
import { getUserID } from "../../util/jwtUtil";

const Profile = () => {
  const { user } = useAuth();

  return user.isEmployee ? (
    <ViewEmployee
      id={getUserID(user.access_token)}
      disableProfile
      disableResponsable
    />
  ) : (
    <ViewClient id={user.customerID} documents={<Documents />} />
  );
};

export default Profile;
