import { useLocation, Link } from "react-router-dom";

import { Menu, Tag } from "antd";

import { employeeMenu, clientMenu } from "../navigation";
import { useAuth } from "../../../providers/auth";

const { SubMenu } = Menu;

export default function MenuItem(props) {
  const { onClose } = props;
  const { user } = useAuth();

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const filteredNavigation = user.isEmployee
    ? employeeMenu.map((nav) => {
        if (nav.children) {
          const children = nav?.children
            ? nav?.children.filter((child) =>
                child.allowedPermissions?.every((allowed) =>
                  user?.permissions.includes(allowed)
                )
              )
            : nav;

          if (children?.length) {
            nav["children"] = children;
          } else {
            return null;
          }
        } else {
          const hasPermission = nav.allowedPermissions?.every((allowed) =>
            user?.permissions.includes(allowed)
          );

          if (!hasPermission) {
            return null;
          }
        }

        return nav;
      })
    : clientMenu;

  const menuItem = filteredNavigation.map((item, index) => {
    if (item?.length < 1) return null;

    if (item?.children) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((itemChildren) => {
            if (!itemChildren.children) {
              const childrenNavLink = itemChildren.navLink.split("/");

              return (
                // Level 2
                <Menu.Item
                  key={itemChildren.id}
                  className={
                    splitLocationUrl ===
                    childrenNavLink[childrenNavLink.length - 2] +
                      "/" +
                      childrenNavLink[childrenNavLink.length - 1]
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                  onClick={onClose}
                >
                  {itemChildren.id === "product-detail" ? (
                    <Link to={itemChildren.navLink}>{itemChildren.title}</Link>
                  ) : itemChildren.id.split("-")[0] === "email" ? (
                    <a
                      href={itemChildren.navLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {itemChildren.title}
                    </a>
                  ) : (
                    <Link to={itemChildren.navLink}>{itemChildren.title}</Link>
                  )}
                </Menu.Item>
              );
            } else {
              return (
                // Level 3
                <SubMenu key={itemChildren.id} title={itemChildren.title}>
                  {itemChildren.children.map((childItem) => {
                    const childrenItemLink = childItem.navLink.split("/");

                    return (
                      <Menu.Item
                        key={childItem.id}
                        className={
                          splitLocationUrl ===
                          childrenItemLink[childrenItemLink.length - 2] +
                            "/" +
                            childrenItemLink[childrenItemLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                        }
                        onClick={onClose}
                      >
                        <Link to={childItem.navLink}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    } else {
      if (!item) return null;

      const itemNavLink = item.navLink ? item.navLink.split("/") : "";

      return (
        // Level 1
        <Menu.Item
          key={item.id}
          icon={item.icon}
          onClick={onClose}
          className={
            splitLocation[splitLocation.length - 2] +
              "/" +
              splitLocation[splitLocation.length - 1] ===
            itemNavLink[itemNavLink.length - 2] +
              "/" +
              itemNavLink[itemNavLink.length - 1]
              ? "ant-menu-item-selected"
              : "ant-menu-item-selected-in-active"
          }
          style={item.tag && { pointerEvents: "none" }}
        >
          {item.tag ? (
            <a
              href="#"
              className="hp-d-flex hp-align-items-center hp-d-flex-between"
            >
              <span>{item.title}</span>
              <Tag
                className="hp-mr-0 hp-border-none hp-text-color-black-100 hp-bg-success-3 hp-border-radius-full hp-px-8"
                style={{ marginRight: -14 }}
              >
                {item.tag}
              </Tag>
            </a>
          ) : (
            <Link to={item.navLink}>{item.title}</Link>
          )}
        </Menu.Item>
      );
    }
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5
          ? splitLocation[splitLocation.length - 3]
          : null,
        splitLocation[splitLocation.length - 2],
      ]}
      theme={"light"}
      className="hp-bg-black-20 hp-bg-dark-90"
    >
      <Menu.ItemGroup title="Menu" />
      {menuItem}
    </Menu>
  );
}
