/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import { useRest } from "../../services/http";
import { StatusType } from "../listFlow/type";
import moment from "moment-timezone";
import { Table, Card, Row, Col } from "antd";

const Dashboard = () => {
  const {
    get: dashboardTotal,
    data: dashboardTotalData,
    status: dashboardTotalStatus,
  } = useRest();

  const {
    get: dashboardFlows,
    loading: dashboardFlowsLoading,
    data: dashboardFlowsData,
  } = useRest();

  const [tablePaginationFlows, setTablePaginationFlows] = useState({
    current: 1,
    pageSize: 15,
  });

  const {
    get: dashboardEmployeeInteractions,
    loading: dashboardEmployeeInteractionsLoading,
    data: dashboardEmployeeInteractionsData,
  } = useRest();

  const [
    tablePaginationEmployeeInteraction,
    setTablePaginationEmployeeInteraction,
  ] = useState({
    current: 1,
    pageSize: 15,
  });

  const {
    get: dashboardLastInteractions,
    loading: dashboardLastInteractionsLoading,
    data: dashboardLastInteractionsData,
  } = useRest();

  const [tablePaginationLastInteractions, setTablePaginationLastInteractions] =
    useState({
      current: 1,
      pageSize: 15,
    });

  useEffect(() => {
    dashboardFlows("dashboardFlows", {
      page: tablePaginationFlows.current,
    });
  }, [tablePaginationFlows?.current]);

  useEffect(() => {
    dashboardTotal("dashboardTotal");
  }, []);

  useEffect(() => {
    dashboardEmployeeInteractions("dashboardEmployeeInteractions", {
      page: tablePaginationEmployeeInteraction.current,
    });
  }, [tablePaginationEmployeeInteraction?.current]);

  useEffect(() => {
    dashboardLastInteractions("dashboardInteractions", {
      page: tablePaginationLastInteractions.current,
    });
  }, [tablePaginationLastInteractions?.current]);

  const columns = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
  ];

  const columnsLastInteractions = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      sorter: (a, b) => a.customer.localeCompare(b.customer),
      sortDirections: ["descend"],
    },
    {
      title: "Serviço",
      dataIndex: "service_title",
      key: "service_title",
      sorter: (a, b) => a.service_title.localeCompare(b.service_title),
      sortDirections: ["descend"],
    },
    {
      title: "Etapa",
      dataIndex: "step_title",
      key: "step_title",
      sorter: (a, b) => a.step_title.localeCompare(b.step_title),
      sortDirections: ["descend"],
    },
    {
      title: "Responsável",
      dataIndex: "assignee",
      key: "assignee",
      sorter: (a, b) => a.assignee.localeCompare(b.assignee),
      sortDirections: ["descend"],
    },
    {
      title: "Prazo",
      dataIndex: "deadline_date",
      key: "deadline_date",
      sorter: (a, b) =>
        String(a.deadline_date)?.localeCompare(String(b.deadline_date)),
      sortDirections: ["descend"],
      render: (record) =>
        record
          ? moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY")
          : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => StatusType[record],
    },
  ];

  const handleTableChangeFlow = (pagination) => {
    setTablePaginationFlows({
      ...tablePaginationFlows,
      current: pagination.current,
    });
  };

  const handleTableChangeEmployeeInteraction = (pagination) => {
    setTablePaginationEmployeeInteraction({
      ...tablePaginationEmployeeInteraction,
      current: pagination.current,
    });
  };

  const handleTableChangeLastInteractions = (pagination) => {
    setTablePaginationLastInteractions({
      ...tablePaginationLastInteractions,
      current: pagination.current,
    });
  };

  return (
    <Wrapper>
      {dashboardTotalStatus.success && (
        <Row gutter={{ sm: 24 }}>
          <Col sm={12}>
            <Card style={{ textAlign: "center" }}>
              <h4>Clientes</h4>
              <h3>{dashboardTotalData?.customers}</h3>
              <span>Clientes cadastrados</span>
            </Card>
          </Col>

          <Col sm={12}>
            <Card style={{ textAlign: "center" }}>
              <h4>Prospecção de Clientes</h4>
              <h3>{dashboardTotalData?.prospect_customers}</h3>
              <span>Prospecção de Clientes cadastradas</span>
            </Card>
          </Col>
        </Row>
      )}

      <Card className="hp-mt-48">
        <h4 className="hp-mb-18">Seus Fluxos</h4>
        <Table
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={dashboardFlowsData?.data}
          // pagination={tablePaginationFlows}
          pagination={false}
          loading={dashboardFlowsLoading}
          onChange={handleTableChangeFlow}
          scroll={{ x: 800 }}
          size={15}
        />
      </Card>
      <Card className="hp-mt-48">
        <h4 className="hp-mb-18">Suas Interações</h4>
        <Table
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={dashboardEmployeeInteractionsData?.data}
          // pagination={tablePaginationEmployeeInteraction}
          pagination={false}
          loading={dashboardEmployeeInteractionsLoading}
          onChange={handleTableChangeEmployeeInteraction}
          scroll={{ x: 800 }}
          size={15}
        />
      </Card>
      <Card className="hp-mt-48">
        <h4 className="hp-mb-18">Últimas interações</h4>
        <Table
          columns={columnsLastInteractions}
          rowKey={(_, index) => index}
          dataSource={dashboardLastInteractionsData?.data}
          // pagination={tablePaginationLastInteractions}
          pagination={false}
          loading={dashboardLastInteractionsLoading}
          onChange={handleTableChangeLastInteractions}
          scroll={{ x: 800 }}
          size={15}
        />
      </Card>
    </Wrapper>
  );
};

export default Dashboard;
