import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../components/layout";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Wrapper>
      <Row
        gutter={[32, 0]}
        className="hp-authentication-page"
        align="middle"
        justify="center"
      >
        <Col>
          <h1>Não Autorizado</h1>
          <p>Você não possui acesso à página solicitada.</p>
          <Button block type="primary" onClick={goBack}>
            Voltar
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Unauthorized;
