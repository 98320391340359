import { ConfigProvider } from "antd";
import pt_BR from "antd/es/locale/pt_BR";
import Login from "./pages/login";
import PublicProspect from "./pages/publicProspect";
import RecoverPassword from "./pages/recoverPassword";
import ConfirmationToken from "./pages/confirmationToken";
import ResetPassword from "./pages/resetPassword";
import ChangePassword from "./pages/changePassword";
import CreateResponsable from "./pages/createResponsable";
import CreateClient from "./pages/createClient";
import ViewClient from "./pages/viewClient";
import CreateRole from "./pages/createRole";
import RoleList from "./pages/roleList";
import ViewRole from "./pages/viewRole";
import ViewEmployee from "./pages/viewEmployee";
import EmployeeList from "./pages/employeeList";
import { AuthProvider } from "./providers/auth";
import Employee from "./pages/createEmployee";
import ViewResponsable from "./pages/viewResponsable";
import ClientList from "./pages/clientList";
import ShowClient from "./pages/showClient";
import CreateInstruction from "./pages/createInstruction";
import ListInstructions from "./pages/listInstructions/listInstructions";
import ViewInstructions from "./pages/viewInstructions";
import CreateService from "./pages/createService";
import ServiceList from "./pages/serviceList";
import Interaction from "./pages/interaction";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { CookiesProvider } from "react-cookie";
import PrivateRoute from "./router";
import { getRoutesPermissions } from "./router/permissions";

import ReactDOM from "react-dom/client";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import ViewService from "./pages/viewService";
import CreateFlux from "./pages/createFlow";
import FlowList from "./pages/listFlow";
import Profile from "./pages/profile";
import Dashboard from "./pages/dashboard";
import ResponsableList from "./pages/responsableList";
import Unauthorized from "./pages/unauthorized";
import NotFound from "./pages/notfound";
import ProspectClient from "./pages/prospectClient";
import ProspectList from "./pages/prospectList";
import ViewProspect from "./pages/viewProspectClient";
import Home from "./pages/home";
import "./global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider locale={pt_BR}>
    <CookiesProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="/" exact element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/public-prospect" element={<PublicProspect />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/confirmation-token" element={<ConfirmationToken />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* Private Routes */}
            {/* Responsáveis */}
            <Route
              path="/responsable-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("responsable-list")}
                >
                  <ResponsableList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-responsable"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions(
                    "create-responsable"
                  )}
                >
                  <CreateResponsable />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-responsable/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-responsable")}
                >
                  <ViewResponsable />
                </PrivateRoute>
              }
            />

            {/* Funcionários */}
            <Route
              path="/employee-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("employee-list")}
                >
                  <EmployeeList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-employee"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-employee")}
                >
                  <Employee />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-employee/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-employee")}
                >
                  <ViewEmployee />
                </PrivateRoute>
              }
            />

            {/* Perfil */}

            <Route
              path="/role-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("role-list")}
                >
                  <RoleList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-role"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-role")}
                >
                  <CreateRole />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-role/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-role")}
                >
                  <ViewRole />
                </PrivateRoute>
              }
            />

            {/* Cliente */}

            <Route
              path="/client-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("client-list")}
                >
                  <ClientList />
                </PrivateRoute>
              }
            />

            <Route
              path="/show-client/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("show-client")}
                >
                  <ShowClient />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-client"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-client")}
                >
                  <CreateClient />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-client/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-client")}
                >
                  <ViewClient />
                </PrivateRoute>
              }
            />

            {/* Instrução */}

            <Route
              path="/instructions-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("instructions-list")}
                >
                  <ListInstructions />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-instruction"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions(
                    "create-instruction"
                  )}
                >
                  <CreateInstruction />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-instruction/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-instruction")}
                >
                  <ViewInstructions />
                </PrivateRoute>
              }
            />

            {/* Serviço/Etapa */}

            <Route
              path="/service-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("service-list")}
                >
                  <ServiceList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-service/"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-service")}
                >
                  <CreateService />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-service/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("view-service")}
                >
                  <ViewService />
                </PrivateRoute>
              }
            />

            {/* Fluxo */}
            <Route
              path="/flow-list/"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("flow-list")}
                >
                  <FlowList />
                </PrivateRoute>
              }
            />

            <Route
              path="/create-flow"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("create-flow")}
                >
                  <CreateFlux />
                </PrivateRoute>
              }
            />

            <Route
              path="/flow-list/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("flow-list")}
                >
                  <FlowList />
                </PrivateRoute>
              }
            />

            <Route
              path="/prospect-client"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("prospect-client")}
                >
                  <ProspectClient />
                </PrivateRoute>
              }
            />

            <Route
              path="/prospect-list"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("prospect-list")}
                >
                  <ProspectList />
                </PrivateRoute>
              }
            />

            <Route
              path="/view-prospect/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("prospect-client")}
                >
                  <ViewProspect />
                </PrivateRoute>
              }
            />

            <Route
              path="/interaction/:id"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("interaction")}
                >
                  <Interaction />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("profile")}
                >
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute
                  allowedPermissions={getRoutesPermissions("dashboard")}
                >
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </CookiesProvider>
  </ConfigProvider>
);
