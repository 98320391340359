/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import {
  Form,
  Input,
  Button,
  Space,
  message,
  Spin,
  Row,
  Col,
  Card,
} from "antd";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { useParams, useNavigate } from "react-router-dom";

const ViewResponsable = () => {
  const {
    get: viewResponsable,
    loading: viewLoading,
    data: viewData,
  } = useRest();

  const {
    put: updateResponsable,
    loading: updateLoading,
    message: updateMessage,
    status: updateStatus,
  } = useRest();

  const [form] = Form.useForm();
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  const submit = () => {
    updateResponsable(
      "updateResponsable",
      { id: id },
      { description: description }
    );
  };

  const editHandle = () => {
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    viewResponsable("viewResponsable", { id: id });
  }, [id]);

  useEffect(() => {
    if (viewData?.description) {
      setDescription(viewData?.description);
      form.setFieldValue("description", viewData?.description);
    }
  }, [viewData]);

  useEffect(() => {
    if (updateStatus?.success) {
      message.success(updateMessage);
      setIsDisabled(true);
      navigate("/responsable-list");
    }
    if (updateStatus?.error) {
      message.error(updateMessage);
    }
  }, [updateStatus]);

  return (
    <Wrapper>
      <Card>
        {viewLoading ? (
          <Row type="flex" align="middle" justify="center">
            <Col>
              <Spin />
            </Col>
          </Row>
        ) : (
          <Form form={form} onFinish={submit} layout="vertical">
            <Form.Item
              name="description"
              className={styled.textArea}
              label="Descrição do responsável"
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório!",
                },
              ]}
            >
              <Input
                placeholder="Descrição do responsável"
                showCount
                maxLength={50}
                onChange={(e) => setDescription(e.target.value)}
                disabled={isDisabled}
              />
            </Form.Item>
            <Space
              size={"middle"}
              align="center"
              className={styled.buttonWrapper}
            >
              <Button
                type={isDisabled ? "primary" : "danger"}
                className={
                  isDisabled
                    ? "hp-hover-bg-primary-1"
                    : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                      " hp-btn-outline"
                }
                onClick={editHandle}
              >
                {isDisabled ? "Editar" : "Cancelar"}
              </Button>

              {!isDisabled && (
                <Button
                  type="primary"
                  className="hp-btn-outline hp-hover-bg-primary-1"
                  loading={updateLoading}
                  htmlType="submit"
                >
                  Confirmar Edição
                </Button>
              )}
            </Space>
          </Form>
        )}
      </Card>
    </Wrapper>
  );
};

export default ViewResponsable;
