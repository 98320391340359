/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate } from "react-router-dom";
import { useAuth } from "../../providers/auth";

export default function Home() {
  const { user } = useAuth();
  const to = user.isEmployee ? "/dashboard" : "/flow-list";

  return <Navigate to={to} />;
}
