import { Col, Row, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Row
      gutter={[32, 0]}
      className="hp-authentication-page"
      align="middle"
      justify="center"
    >
      <Col>
        <h1>Página Não Encontrada</h1>
        <p>A página solicitada não foi encontrada.</p>
        <Button block type="primary" onClick={goBack}>
          Voltar
        </Button>
      </Col>
    </Row>
  );
};

export default NotFound;
