/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { Permissions, hasPermission } from "../../router/permissions";
import { useAuth } from "../../providers/auth";

const RoleList = () => {
  const { get, loading, data } = useRest();
  const { remove, status, message: messageRole } = useRest();

  const { user } = useAuth();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    get("roleList", {
      is_select: 0,
    });
  }, [tablePagination?.current]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const removeRole = (id) => {
    remove("removeRole", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (status.success) {
      get("roleList", { page: tablePagination.current, is_select: 0 });
      message.success("Perfil excluído com sucesso!");
    }
    if (status.error) {
      message.error(messageRole);
    }
  }, [status]);

  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend"],
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          {hasPermission(user, Permissions.cadastrar_perfis) && (
            <Link to={"/view-role/" + record.id} className={styled.icon}>
              <i className="ri-edit-fill" title="Editar Perfil" />
            </Link>
          )}

          {hasPermission(user, Permissions.remover_perfis) && (
            <Popconfirm
              title={"Tem certeza que deseja deletar o perfil: " + record.title}
              onConfirm={() => removeRole(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Perfil"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Table
        className={styled.table}
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={data}
        pagination={tablePagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        size={15}
      />
    </Wrapper>
  );
};

export default RoleList;
