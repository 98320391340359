import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import { Form, Input, Button, Space, message, Card } from "antd";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";

const CreateResponsable = () => {
  const { post: createResponsable, status, loading } = useRest();
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");

  const submit = () => {
    createResponsable("createResponsable", { description: description });
  };

  useEffect(() => {
    if (status?.success) {
      setDescription("");
      form.resetFields();
      message.success("Responsável criado com sucesso!");
    }
    if (status?.error) {
      message.error(
        "Ocorreu um erro na criação do responsável. Tente novamente!"
      );
    }
  }, [status, form]);

  return (
    <Wrapper>
      <Card>
        <Form form={form} onFinish={submit} layout="vertical">
          <Form.Item
            name="name"
            label="Criação de responsável"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input
              placeholder="Criação de responsável"
              showCount
              maxLength={50}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Space
            size={"middle"}
            align="center"
            className={styled.buttonWrapper}
          >
            <Button
              type="primary"
              className="hp-btn-outline hp-hover-bg-primary-1"
              loading={loading}
              htmlType="submit"
            >
              Criar responsável
            </Button>
          </Space>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default CreateResponsable;
