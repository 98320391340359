/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Row, Col, Button, Alert, message } from "antd";
import { useState, useEffect, useMemo } from "react";
import { useRest } from "../../../services/http";
import "react-phone-input-2/lib/style.css";

const Security = ({ form, customerId, defaultData, setData }) => {
  const [formValues, setFormValues] = useState({
    portal_name: defaultData?.portal_name || "",
    link: defaultData?.link || "",
    user: defaultData?.user || "",
    password: defaultData?.password || "",
    observation: defaultData?.observation || "",
  });

  const {
    post: portalPost,
    status: portalStatus,
    loading: portalLoading,
    message: portalMessage,
    data: portalData,
  } = useRest();

  const submit = () => {
    if (formValues.portal_name === "") {
      message.error("Preencha todos os campos corretamente!");
    } else {
      portalPost("createCustomerPortal", {
        ...formValues,
        ...customerId,
      });
    }
  };

  useEffect(() => {
    if (portalStatus.success) {
      message.success("Portal adicionado com sucesso!");
      form.resetFields();
      setFormValues({
        portal_name: "",
        link: "",
        user: "",
        password: "",
        observation: "",
      });
    } else if (portalStatus.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [portalStatus]);

  useEffect(() => {
    setData(formValues);
  }, [formValues]);

  const isRequired = useMemo(() => {
    return formValues?.portal_name?.length > 0;
  }, [formValues?.portal_name]);

  const errorMessage = useMemo(() => {
    if (portalData) {
      if (Object.values(portalData)?.length > 0) {
        return Object.values(portalData).map((data) => (
          <>
            {data}
            <br />
          </>
        ));
      } else {
        return portalMessage;
      }
    }
  }, [portalData]);

  return (
    <>
      {portalStatus.error && (
        <Alert
          message="Dados incorretos:"
          description={errorMessage}
          type="error"
        />
      )}
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Nome do portal"
            name="portal_name"
            value={formValues.portal_name}
            onChange={(e) =>
              setFormValues({ ...formValues, portal_name: e.target.value })
            }
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="URL do portal"
            name="link"
            value={formValues.link}
            onChange={(e) =>
              setFormValues({ ...formValues, link: e.target.value })
            }
          >
            <Input showCount maxLength={255} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Usuário de Acesso"
            name="user-security"
            value={formValues.user}
            onChange={(e) =>
              setFormValues({ ...formValues, user: e.target.value })
            }
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Senha de acesso"
            name="password-security"
            value={formValues.password}
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Observação"
            name="observation"
            value={formValues.observation}
            onChange={(e) =>
              setFormValues({ ...formValues, observation: e.target.value })
            }
          >
            <Input.TextArea allowClear max={250} showCount />
          </Form.Item>
        </Col>
      </Row>

      <Button
        type="primary"
        htmlType="submit"
        onClick={submit}
        disabled={!isRequired}
        loading={portalLoading}
        style={{
          marginBottom: "20px",
        }}
      >
        Adicionar
      </Button>
    </>
  );
};

export default Security;
