/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Form, Input, Button, Alert, message } from "antd";
import { Link } from "react-router-dom";
import { useRest } from "../../services/http";

export default function RecoverPassword() {
  const [form] = Form.useForm();
  const {
    post: postRecoverPassword,
    status,
    loading,
    message: recoverPasswordMessage,
  } = useRest();
  const submit = async () => {
    const isFormValid = await form.validateFields();

    if (isFormValid) {
      const loginData = {
        login: form.getFieldValue("login"),
      };
      await postRecoverPassword("recoverPassword", loginData);
    }
  };
  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <Col lg={32} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={12}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            {recoverPasswordMessage && (
              <Alert message={recoverPasswordMessage} type="warning" />
            )}
            <br />
            <h1 className="hp-mb-sm-0">Recuperar senha</h1>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              form={form}
            >
              <Form.Item
                label="E-mail ou usuário:"
                className="hp-mb-16"
                name="login"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                  {
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Email inválido!",
                  },
                ]}
              >
                <Input id="login" />
              </Form.Item>
              {status?.error && (
                <Alert
                  message={message || "Erro de validação"}
                  type="error"
                  closable
                />
              )}
              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={submit}
                  loading={loading}
                >
                  Confirmar
                </Button>
              </Form.Item>
            </Form>
            {recoverPasswordMessage && (
              <Row align="middle" justify="space-between">
                <Link
                  className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                  to="/login"
                >
                  Voltar para login
                </Link>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
