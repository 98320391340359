export const getExpiresDate = (expiresIn) => {
  const now = Math.floor(Date.now() / 1000);
  return now + expiresIn;
};

export const isExpired = (expiresIn) => {
  const now = Math.floor(Date.now() / 1000);
  if (expiresIn) {
    return now > Number(expiresIn);
  } else {
    return true;
  }
};
