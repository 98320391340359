/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import { Spin, Row, Col, Button, Tabs, message } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRest } from "../../services/http";
import { useAuth } from "../../providers/auth";
import {
  HairColor,
  EyeColor,
  SkinColor,
  StatusCoren,
  InstitutionType,
} from "./type";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const ShowClient = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const {
    get: viewCustomer,
    data: viewCustomerData,
    loading: viewCustomerLoading,
  } = useRest();
  const { get: customerListPortals, data: viewPortalData } = useRest();
  const { get: customerListEducation, data: educationList } = useRest();
  const { get: customerListEmployer, data: viewEmployerData } = useRest();

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [tablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    viewCustomer("viewCustomer", { id });
  }, [id]);

  useEffect(() => {
    customerListEducation("customerListEducation", {
      page: tablePagination.current,
      id: id,
    });
    customerListPortals("customerListPortals", {
      page: tablePagination.current,
      id: id,
    });
    customerListEmployer("customerListEmployer", {
      page: tablePagination.current,
      id: id,
    });
  }, [tablePagination?.current]);

  const downloadFile = async (filePath) => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}${filePath}`, {
      headers: {
        Authorization: `${"Bearer " + user?.access_token}`,
      },
    });

    if (data?.status !== 200) {
      message.error("Não foi possível baixar o arquivo. Tente novamente!");
      return;
    }

    const response = await data.blob();
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = filePath.replace("attachment/", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getMaritalStatus = (status) => {
    switch (status) {
      case "SNG":
        return "Solteiro(a)";
      case "MAR":
        return "Casado(a)";
      case "SEP":
        return "Separado(a)";
      case "DIV":
        return "Divorciado(a)";
      case "WID":
        return "Viúvo(a)";
      default:
        return "Desconhecido";
    }
  };

  return (
    <Wrapper>
      {viewCustomerLoading ? (
        <Row type="flex" align="middle" justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        <>
          <Tabs defaultActiveKey="1" type="card" size="small">
            <TabPane tab="Dados Pessoais" key="1">
              <p>
                <strong>Nome: </strong>
                {viewCustomerData?.user.name
                  ? viewCustomerData?.user.name
                  : "-"}
              </p>
              <p>
                <strong>Email: </strong>
                {viewCustomerData?.user.email
                  ? viewCustomerData?.user.email
                  : "-"}
              </p>
              <p>
                <strong>Usuário: </strong>
                {viewCustomerData?.user.username
                  ? viewCustomerData?.user.username
                  : "-"}
              </p>
              <p>
                <strong>Telefone: </strong>
                {viewCustomerData?.phone_number
                  ? viewCustomerData?.phone_number
                  : "-"}
              </p>
              <p>
                <strong>Data de nascimento: </strong>
                {viewCustomerData?.birthdate
                  ? moment(viewCustomerData?.birthdate).format("DD/MM/YYYY")
                  : "-"}
              </p>
              <p>
                <strong>RG: </strong>
                {viewCustomerData?.rg ? viewCustomerData?.rg : "-"}
              </p>
              <p>
                <strong>Órgão Emissor / UF: </strong>
                {viewCustomerData?.issued_by
                  ? viewCustomerData?.issued_by
                  : "-"}
              </p>
              <p>
                <strong>CPF: </strong>
                {viewCustomerData?.cpf ? viewCustomerData?.cpf : "-"}
              </p>
              <p>
                <strong>Estado Civil: </strong>
                {viewCustomerData?.marital_status
                  ? getMaritalStatus(viewCustomerData?.marital_status)
                  : "-"}
              </p>
              <p>
                <strong>Estado: </strong>
                {viewCustomerData?.uf_of_birth
                  ? viewCustomerData?.uf_of_birth
                  : "-"}
              </p>
              <p>
                <strong>Cidade: </strong>
                {viewCustomerData?.city_of_birth
                  ? viewCustomerData?.city_of_birth
                  : "-"}
              </p>
            </TabPane>

            <TabPane tab="Documentos" key="2">
              {viewCustomerData?.attachments?.length > 0
                ? viewCustomerData?.attachments.map((item) => (
                    <p>
                      <a onClick={() => downloadFile(item.path)}>
                        {item.alias || item.name}
                      </a>
                    </p>
                  ))
                : "Sem documentos cadastrados."}
            </TabPane>

            <TabPane tab="Endereço" key="3">
              <p>
                <strong>País: </strong>
                {viewCustomerData?.country ? viewCustomerData?.country : "-"}
              </p>
              <p>
                <strong>CEP: </strong>
                {viewCustomerData?.cep ? viewCustomerData?.cep : "-"}
              </p>
              <p>
                <strong>Estado: </strong>
                {viewCustomerData?.uf ? viewCustomerData?.uf : "-"}
              </p>
              <p>
                <strong>Cidade: </strong>
                {viewCustomerData?.city ? viewCustomerData?.city : "-"}
              </p>
              <p>
                <strong>Rua: </strong>
                {viewCustomerData?.street ? viewCustomerData?.street : "-"}
              </p>
              <p>
                <strong>Número: </strong>
                {viewCustomerData?.street_number
                  ? viewCustomerData?.street_number
                  : "-"}
              </p>
              <p>
                <strong>Complemento: </strong>
                {viewCustomerData?.street_complement
                  ? viewCustomerData?.street_complement
                  : "-"}
              </p>
            </TabPane>
            <TabPane tab="Dados Complementares" key="4">
              <p>
                <strong>Passaporte: </strong>
                {viewCustomerData?.passport_number
                  ? viewCustomerData?.passport_number
                  : "-"}
              </p>
              <p>
                <strong>Social Security Number - SSN: </strong>
                {viewCustomerData?.ssn ? viewCustomerData?.ssn : "-"}
              </p>
              <p>
                <strong>Nome de solteira da mãe: </strong>
                {viewCustomerData?.mothers_maiden_name
                  ? viewCustomerData?.mothers_maiden_name
                  : "-"}
              </p>
              <p>
                <strong>Raça: </strong>
                {viewCustomerData?.skin_color
                  ? SkinColor[viewCustomerData?.skin_color]
                  : "-"}
              </p>
              <p>
                <strong>Cor dos olhos: </strong>
                {viewCustomerData?.eyes_color
                  ? EyeColor[viewCustomerData?.eyes_color]
                  : "-"}
              </p>
              <p>
                <strong>Cor dos cabelos: </strong>
                {viewCustomerData?.hair_color
                  ? HairColor[viewCustomerData?.hair_color]
                  : "-"}
              </p>
              <p>
                <strong>Altura em metros: </strong>
                {viewCustomerData?.weight_mt
                  ? viewCustomerData?.weight_mt + "m"
                  : "-"}
              </p>
              <p>
                <strong>Peso em KG: </strong>
                {viewCustomerData?.height_kg
                  ? viewCustomerData?.height_kg + "KG"
                  : "-"}
              </p>
              <p>
                <strong>Nome do seu melhor amigo ou amiga na infância: </strong>
                {viewCustomerData?.childhood_friend_name
                  ? viewCustomerData?.childhood_friend_name
                  : "-"}
              </p>
              <p>
                <strong>Nome do meio da sua mãe: </strong>
                {viewCustomerData?.mother_middle_name
                  ? viewCustomerData?.mother_middle_name
                  : "-"}
              </p>
              <p>
                <strong>Qual seu apelido na infância: </strong>
                {viewCustomerData?.childhood_nickname
                  ? viewCustomerData?.childhood_nickname
                  : "-"}
              </p>
              <p>
                <strong>Data do aniversário da mãe: </strong>
                {viewCustomerData?.mother_birthday
                  ? moment(viewCustomerData?.mother_birthday).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </p>
              <p>
                <strong>Data do aniversário do pai: </strong>
                {viewCustomerData?.father_birthday
                  ? moment(viewCustomerData?.father_birthday).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </p>
            </TabPane>
            <TabPane tab="Dados Licença COREN" key="5">
              <p>
                <strong>Número do Coren: </strong>
                {viewCustomerData?.coren_number
                  ? viewCustomerData?.coren_number
                  : "-"}
              </p>
              <p>
                <strong>Estado do Coren: </strong>
                {viewCustomerData?.coren_state
                  ? viewCustomerData?.coren_state
                  : "-"}
              </p>
              <p>
                <strong>Status coren: </strong>
                {viewCustomerData?.coren_status
                  ? StatusCoren[viewCustomerData?.coren_status]
                  : "-"}
              </p>
              <p>
                <strong>Data da emissão do Coren: </strong>
                {viewCustomerData?.coren_license_issue_date
                  ? moment(viewCustomerData?.coren_license_issue_date).format(
                      "DD/MM/YYYY"
                    )
                  : "-"}
              </p>
              <p>
                <strong>Coren já foi revogada? </strong>
                {viewCustomerData?.coren_revoked ? "Sim" : "Não"}
              </p>
            </TabPane>
            <TabPane tab="Dados de Segurança" key="6">
              {viewPortalData?.data.length > 0
                ? viewPortalData?.data.map((item, index) => (
                    <>
                      <h4 style={{ textDecoration: "underline" }}>
                        Portal {index + 1}:
                      </h4>
                      <p>
                        <strong>Nome: </strong>
                        {item.portal_name ? item.portal_name : "-"}
                      </p>
                      <p>
                        <strong>URL: </strong>
                        {item.link ? item.link : "-"}
                      </p>
                      <p>
                        <strong>Usuário de Acesso: </strong>
                        {item.user ? item.user : "-"}
                      </p>
                      <p>
                        <strong>Senha de Acesso: </strong>
                        {item.password ? item.password : "-"}
                      </p>
                      <p>
                        <strong>Observação: </strong>
                        {item.observation ? item.observation : "-"}
                      </p>
                    </>
                  ))
                : "Sem dados cadastrados."}
            </TabPane>
            <TabPane tab="Dados de Ensino" key="7">
              {educationList?.data.length > 0
                ? educationList?.data.map((item, index) => (
                    <>
                      <h4 style={{ textDecoration: "underline" }}>
                        Instituição {index + 1}:
                      </h4>
                      <p>
                        <strong>Tipo: </strong>
                        {item.institution_name
                          ? InstitutionType[item.education_type]
                          : "-"}
                      </p>
                      <p>
                        <strong>Nome: </strong>
                        {item.institution_name ? item.institution_name : "-"}
                      </p>
                      <p>
                        <strong>País: </strong>
                        {item.country ? item.country : "-"}
                      </p>
                      <p>
                        <strong>CEP: </strong>
                        {item.cep ? item.cep : "-"}
                      </p>
                      <p>
                        <strong>Estado: </strong>
                        {item.uf ? item.uf : "-"}
                      </p>
                      <p>
                        <strong>Cidade: </strong>
                        {item.city ? item.city : "-"}
                      </p>
                      <p>
                        <strong>Rua: </strong>
                        {item.street ? item.street : "-"}
                      </p>
                      <p>
                        <strong>Número: </strong>
                        {item.street_number ? item.street_number : "-"}
                      </p>
                      <p>
                        <strong>Complemento: </strong>
                        {item.street_complement ? item.street_complement : "-"}
                      </p>
                      <p>
                        <strong>Data de Início: </strong>
                        {item.start_date
                          ? moment(item.start_date).format("DD/MM/YYYY")
                          : "-"}
                      </p>
                      <p>
                        <strong>Data de Conclusão: </strong>
                        {item.end_date
                          ? moment(item.end_date).format("DD/MM/YYYY")
                          : "-"}
                      </p>
                      {item.education_type === "UNI" && item.university_closed && (
                        <>
                          <p>
                            <strong>Faculdade fechou? </strong>
                            {item.university_closed ? "Sim" : "Não"}
                          </p>
                          <p>
                            <strong>Quem assumiu? </strong>
                            {item.university_closed_transfer
                              ? item.university_closed_transfer
                              : "-"}
                          </p>
                          <p>
                            <strong>
                              Faculdade mudou de nome ou foi transferida?{" "}
                            </strong>
                            {item.university_changed_name
                              ? item.university_changed_name
                              : "-"}
                          </p>
                        </>
                      )}
                      {item.education_type === "UNI" && (
                        <>
                          <p>
                            <strong>Data de Colação de Grau: </strong>
                            {item.graduation_date
                              ? moment(item.graduation_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </p>
                        </>
                      )}
                    </>
                  ))
                : "Sem dados cadastrados."}
            </TabPane>
            <TabPane tab="Dados de Empregador" key="8">
              {viewEmployerData?.data.length > 0
                ? viewEmployerData?.data.map((item, index) => (
                    <>
                      <h4 style={{ textDecoration: "underline" }}>
                        Empregador {index + 1}:
                      </h4>
                      <p>
                        <strong>Empresa: </strong>
                        {item.company_name ? item.company_name : "-"}
                      </p>
                      <p>
                        <strong>Nome do chefe: </strong>
                        {item.boss_name ? item.boss_name : "-"}
                      </p>
                      <p>
                        <strong>Cargo: </strong>
                        {item.job_role ? item.job_role : "-"}
                      </p>
                      <p>
                        <strong>País: </strong>
                        {item.country ? item.country : "-"}
                      </p>
                      <p>
                        <strong>CEP: </strong>
                        {item.cep ? item.cep : "-"}
                      </p>
                      <p>
                        <strong>Estado: </strong>
                        {item.uf ? item.uf : "-"}
                      </p>
                      <p>
                        <strong>Cidade: </strong>
                        {item.city ? item.city : "-"}
                      </p>
                      <p>
                        <strong>Rua: </strong>
                        {item.street ? item.street : "-"}
                      </p>
                      <p>
                        <strong>Número: </strong>
                        {item.street_number ? item.street_number : "-"}
                      </p>
                      <p>
                        <strong>Complemento: </strong>
                        {item.street_complement ? item.street_complement : "-"}
                      </p>
                      <p>
                        <strong>Data de Início: </strong>
                        {item.start_date
                          ? moment(item.start_date).format("DD/MM/YYYY")
                          : "-"}
                      </p>
                      <p>
                        <strong>Data de Saída: </strong>
                        {item.end_date
                          ? moment(item.end_date).format("DD/MM/YYYY")
                          : "-"}
                      </p>
                    </>
                  ))
                : "Sem dados cadastrados."}
            </TabPane>
          </Tabs>
          <br />
          <Button onClick={goBack}>Voltar</Button>
          <br />
          <br />
        </>
      )}
    </Wrapper>
  );
};

export default ShowClient;
