/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import {
  Input,
  Form,
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Alert,
  Card,
} from "antd";
import { useRest } from "../../services/http";
import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";

const ViewRole = () => {
  const [form] = Form.useForm();
  const [permissions, setPermissions] = useState([]);
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    permissions: [],
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const { get, data: dataGet, status: statusGet } = useRest();
  const { put, data, status, message: messageRole } = useRest();
  const {
    get: getPermissions,
    data: dataPermissions,
    status: statusPermissions,
  } = useRest();

  useEffect(() => {
    get("viewRole", { id });
    getPermissions("viewPermissions");
  }, []);

  useEffect(() => {
    if (statusPermissions.success && dataPermissions) {
      const result = dataPermissions.reduce((group, item) => {
        const { module } = item;
        group[module] = group[module] ?? [];
        group[module].push(item);
        return group;
      }, {});
      setPermissions(result);
    }
  }, [dataPermissions, statusPermissions]);

  const onChangeCheckbox = (checked, id) => {
    const { permissions } = formValues;
    let newCheckedPermissions = permissions;

    if (checked) {
      newCheckedPermissions.push(id);
    } else {
      newCheckedPermissions = newCheckedPermissions.filter((e) => e !== id);
    }

    setFormValues({ ...formValues, permissions: newCheckedPermissions });
  };

  const submit = () => {
    if (formValues.title === "" || formValues.description === "") {
      message.error("Preencha todos os campos obrigatórios.");
    } else {
      put("updateRole", { id }, formValues);
    }
  };

  useEffect(() => {
    if (status.success) {
      message.success("Perfil atualizado com sucesso!");
      navigate("/role-list");
    } else if (status.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [status, data, messageRole]);

  useEffect(() => {
    if (statusGet.error) {
      message.error("Erro ao buscar os dados. Tente novamente.");
    } else if (statusGet.success && dataGet) {
      const { description, title, permissions } = dataGet;

      form.setFieldValue("titulo", title);
      form.setFieldValue("descricao", description);

      setFormValues({
        title,
        description,
        permissions: permissions.map((permission) => permission.id),
      });
    }
  }, [dataGet, statusGet]);

  const errorMessage = useMemo(() => {
    if (data) {
      if (Object.values(data)?.length > 0) {
        return Object.values(data).map((data) => (
          <>
            {data}
            <br />
          </>
        ));
      } else {
        return messageRole;
      }
    }
  }, [data]);

  return (
    <Wrapper>
      <Card>
        <Form layout="vertical" form={form} autoComplete="off" name="aee">
          {status.error && (
            <Alert
              message="Dados incorretos:"
              description={errorMessage}
              type="error"
            />
          )}

          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Título"
                name="titulo"
                onChange={(e) =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Descrição"
                name="descricao"
                onChange={(e) =>
                  setFormValues({ ...formValues, description: e.target.value })
                }
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 16, xs: 10 }}>
            {Object.values(permissions).map((items, index) => (
              <Col xs={24} sm={6} key={index}>
                <div style={{ margin: "10px 0" }}>
                  <h4>{Object.keys(permissions)[index]}</h4>
                  {items.map((item, index) => (
                    <div key={index}>
                      <Checkbox
                        checked={formValues.permissions.includes(item.id)}
                        onChange={(e) =>
                          onChangeCheckbox(e.target.checked, item.id)
                        }
                      >
                        {item.name}
                      </Checkbox>
                      <br />
                    </div>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Form>
        <Button
          style={{ float: "right", marginBottom: "10px" }}
          type="primary"
          htmlType="submit"
          onClick={submit}
        >
          Editar Perfil
        </Button>
      </Card>
    </Wrapper>
  );
};

export default ViewRole;
