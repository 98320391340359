import { useState } from "react";

import { Layout, Row, Col } from "antd";
import { motion } from "framer-motion";

import MenuLogo from "../logo";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";

const { Sider } = Layout;

export default function Sidebar(props) {
  const { visible, setVisible } = props;

  // Collapsed
  const [collapsed] = useState(false);

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={316}
      className="hp-sidebar hp-bg-black-20 hp-bg-color-dark-90 hp-border-right-1 hp-border-color-black-40 hp-border-color-dark-80"
    >
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
        className="hp-d-flex hp-h-100"
        style={{ flexDirection: "column" }}
      >
        <Row align="bottom" justify="space-between">
          <Col>{collapsed === false ? <MenuLogo onClose={onClose} /> : ""}</Col>

          {collapsed && (
            <Col span={24} className="hp-mt-12 hp-d-flex-full-center">
              <MenuLogo onClose={onClose} small={true} />
            </Col>
          )}
        </Row>

        <MenuItem onClose={onClose} />

        <MenuFooter onClose={onClose} collapsed={collapsed} />

        <MenuMobile onClose={onClose} visible={visible} />
      </motion.div>
    </Sider>
  );
}
