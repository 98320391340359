/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Space,
  message,
  Alert,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import cepPromise from "cep-promise";
import { useState, useEffect, useMemo } from "react";
import { BrazilianStates } from "../../../enums/brazilianStates";
import InputMask from "react-input-mask";
import { useRest } from "../../../services/http";
import "react-phone-input-2/lib/style.css";
import { Country } from "../../../enums/country";

const Employer = ({ form, customerId, defaultData, setData }) => {
  const [formValues, setFormValues] = useState({
    company_name: defaultData?.company_name || "",
    boss_name: defaultData?.boss_name || "",
    job_role: defaultData?.job_role || "",
    country: defaultData?.country || "Brasil",
    uf: defaultData?.uf || "",
    city: defaultData?.city || "",
    cep: defaultData?.cep || "",
    street: defaultData?.street || "",
    street_number: defaultData?.street_number || "",
    street_complement: defaultData?.street_complement || "",
    start_date: defaultData?.start_date || "",
    end_date: defaultData?.end_date || "",
  });

  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf-employer", address.state);
      form.setFieldValue("city-employer", address.city);
      form.setFieldValue("street-employer", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };

  const {
    post: employerPost,
    status: employerStatus,
    loading: employerLoading,
    message: employerMessage,
    data: employerData,
  } = useRest();

  const add = () => {
    if (
      formValues.company_name === "" ||
      formValues.boss_name === "" ||
      formValues.job_role === "" ||
      formValues.country === "" ||
      formValues.uf === "" ||
      formValues.city === "" ||
      formValues.cep === "" ||
      formValues.street === "" ||
      formValues.start_date === ""
    ) {
      message.error("Preencha todos os campos corretamente!");
    } else {
      employerPost("createCustomerEmployer", {
        ...formValues,
        ...customerId,
      });
    }
  };

  useEffect(() => {
    setData(formValues);
  }, [formValues]);

  useEffect(() => {
    if (employerStatus.success) {
      message.success("Empregador adicionado com sucesso!");
      form.resetFields();
      setFormValues({
        company_name: "",
        boss_name: "",
        job_role: "",
        country: "",
        uf: "",
        city: "",
        cep: "",
        street: "",
        street_number: "",
        street_complement: "",
        start_date: "",
        end_date: "",
      });
    } else if (employerStatus.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [employerStatus]);

  const isRequired = useMemo(() => {
    return formValues?.company_name?.length > 0;
  }, [formValues?.company_name]);

  const errorMessage = useMemo(() => {
    if (employerData) {
      if (Object.values(employerData)?.length > 0) {
        return Object.values(employerData).map((data) => (
          <>
            {data}
            <br />
          </>
        ));
      } else {
        return employerMessage;
      }
    }
  }, [employerData]);

  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));

  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues.uf
  );

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));

  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  const isBrasil = useMemo(() => {
    return formValues.country === "Brasil";
  }, [formValues]);

  return (
    <>
      {employerStatus.error && (
        <Alert
          message="Dados incorretos:"
          description={errorMessage}
          type="error"
        />
      )}
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Nome do Empregador"
            name="company_name"
            value={formValues.company_name}
            onChange={(e) =>
              setFormValues({ ...formValues, company_name: e.target.value })
            }
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Nome do Chefe"
            name="boss_name"
            value={formValues.boss_name}
            onChange={(e) =>
              setFormValues({ ...formValues, boss_name: e.target.value })
            }
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Cargo"
            name="job_role"
            value={formValues.job_role}
            onChange={(e) =>
              setFormValues({ ...formValues, job_role: e.target.value })
            }
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={6}>
          <Form.Item
            label="País"
            name="country"
            initialValue={formValues.country}
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="País"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, country: value })
              }
            >
              {CountryOptions}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={6}>
          <Form.Item>
            <Space>
              <Form.Item
                label={isBrasil ? "CEP" : "Código Postal"}
                name="cep-employer"
                rules={[
                  {
                    required: isRequired,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <InputMask
                  mask={isBrasil ? "99999-999" : null}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      cep: e.target.value.replace(/[^\d]/g, ""),
                    })
                  }
                >
                  <Input />
                </InputMask>
              </Form.Item>

              {isBrasil && (
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  style={{ marginTop: "6px" }}
                  onClick={getAddress}
                />
              )}
            </Space>
          </Form.Item>
        </Col>

        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Estado"
              name="uf-employer"
              rules={[
                {
                  required: isRequired,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Estado"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                onChange={(value) =>
                  setFormValues({
                    ...formValues,
                    uf: value,
                    city: "",
                  })
                }
              >
                {UFOptions}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Estado"
              name="uf"
              initialValue={formValues.uf}
              onChange={(e) =>
                setFormValues({ ...formValues, uf: e.target.value })
              }
              rules={[
                {
                  required: isRequired,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>

        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Cidade"
              name="city-employer"
              rules={[
                {
                  required: isRequired,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Cidade"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                disabled={!formValues?.uf}
                onChange={(value) =>
                  setFormValues({ ...formValues, city: value })
                }
              >
                {CityOptions || []}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Cidade"
              name="city"
              initialValue={formValues.city}
              onChange={(e) =>
                setFormValues({ ...formValues, city: e.target.value })
              }
              rules={[
                {
                  required: isRequired,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Rua"
            name="street-employer"
            value={formValues.street}
            onChange={(e) =>
              setFormValues({ ...formValues, street: e.target.value })
            }
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={4}>
          <Form.Item
            label="Número"
            name="street_number-employer"
            value={formValues.street_number}
            onChange={(e) =>
              setFormValues({ ...formValues, street_number: e.target.value })
            }
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Complemento"
            name="street_complement-employer"
            value={formValues.street_complement}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                street_complement: e.target.value,
              })
            }
            rules={[
              {
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data de Início"
            name="start_date-employer"
            value={formValues.start_date}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                start_date: e.target.value,
              })
            }
            rules={[
              {
                required: isRequired,
                message: "Campo obrigatório!",
              },
              {
                validator() {
                  if (
                    formValues.end_date !== "" &&
                    formValues.start_date > formValues.end_date
                  ) {
                    return Promise.reject(
                      new Error(
                        "Data de Início não pode ser maior que Data de Saída"
                      )
                    );
                  }
                },
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data de Saída"
            name="end_date-employer"
            value={formValues.end_date}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                end_date: e.target.value,
              })
            }
            rules={[
              {
                validator() {
                  if (
                    formValues.end_date < formValues.start_date &&
                    formValues.end_date
                  ) {
                    return Promise.reject(
                      new Error(
                        "Data de Saída não pode ser menor que data de inicio"
                      )
                    );
                  }
                },
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
        onClick={add}
        disabled={!isRequired}
        loading={employerLoading}
        style={{
          marginBottom: "20px",
        }}
      >
        Adicionar
      </Button>
    </>
  );
};

export default Employer;
