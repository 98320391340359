import { useState } from "react";

import { Layout, Row, Col } from "antd";

import Sidebar from "../menu/Sidebar";
import Logo from "../logo";
import MenuFooter from "../footer";
import styled from "./style.module.scss";
const { Content, Header } = Layout;

export default function VerticalLayout(props) {
  const { children } = props;

  const [visible, setVisible] = useState(false);

  const menuHandler = () => {
    setVisible(!visible);
  };

  return (
    <Layout className="hp-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />

      <Layout className="hp-bg-black-20 hp-bg-color-dark-90">
        <Header className={styled.header}>
          <Row justify="center">
            <i
              className={"ri-align-justify-fill ri-2x " + styled.menuIcon}
              onClick={menuHandler}
            />
            <div className={styled.logoWrapper}>
              <Logo />
            </div>
          </Row>
        </Header>
        <Content className={"hp-mb-24 hp-content-main " + styled.content}>
          {children}
        </Content>

        <MenuFooter />
      </Layout>
    </Layout>
  );
}
