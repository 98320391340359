/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import Portal from "./index";
import { useRest } from "../../../services/http";
import { Popconfirm, message, Modal, Button, Table, Alert } from "antd";
import { useAuth } from "../../../providers/auth";
import { useParams } from "react-router-dom";
import { getCustomerID } from "../../../util/jwtUtil";

const ListPortals = () => {
  const {
    post,
    put,
    remove,
    data: dataCreate,
    loading,
    status,
    message: messagePortal,
  } = useRest();
  const { get, data } = useRest();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [portalId, setPortalId] = useState(0);
  const [updatedData, setUpdatedData] = useState({});
  const { user } = useAuth();
  let { id } = useParams();
  if (!id) id = getCustomerID(user.access_token);

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    get("customerListPortals", {
      page: tablePagination.current,
      id: id,
    });
  }, [tablePagination?.current, status]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const confirm = (id) => {
    remove("removeCustomerPortal", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (status?.success) {
      setUpdatedData({});
      setIsModalOpen(false);

      if (portalId) {
        message.success("Portal atualizado com sucesso!");
      } else {
        message.success("Portal criado com sucesso!");
      }
    } else if (status?.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [status]);

  const errorMessage = useMemo(() => {
    if (dataCreate) {
      if (Object.values(dataCreate)?.length > 0) {
        return Object.values(dataCreate).map((item) => (
          <>
            {item}
            <br />
          </>
        ));
      } else {
        return messagePortal;
      }
    }
  }, [dataCreate]);

  const columns = [
    {
      title: "Nome do Portal",
      dataIndex: "portal_name",
      key: "portal_name",
    },
    {
      title: "URL",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          <a className={styled.icon}>
            <i
              className="ri-edit-fill"
              title="Editar Portal"
              onClick={() => showModal(record.id)}
            />
          </a>
          <Popconfirm
            title={"Deseja remover o portal: " + record.portal_name + "?"}
            okText="Confirmar"
            cancelText="Cancelar"
            onConfirm={() => confirm(record.id)}
          >
            <a className={styled.icon}>
              <i
                className={styled.iconDelete + " ri-delete-bin-fill"}
                title="Deletar Portal"
              />
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const showModal = (id = 0) => {
    setPortalId(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    !portalId
      ? post("createCustomerPortal", {
          ...updatedData,
          customer_id: parseInt(id),
        })
      : put("updateCustomerPortal", { id: portalId }, { ...updatedData });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUpdatedData({});
  };

  return (
    <>
      <br />
      <Button
        type="primary"
        onClick={() => showModal()}
        className="hp-bg-black-100 hp-border-color-black-100 hp-hover-bg-black-80 hp-hover-border-color-black-80"
      >
        Adicionar
      </Button>
      <br />
      <br />
      <Table
        className={styled.table}
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={data?.data}
        pagination={tablePagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        size={15}
      />
      <Modal
        title={!portalId ? "Adicionar Portal" : "Editar Portal"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="70%"
        okText={!portalId ? "Adicionar" : "Editar"}
      >
        {status.error && (
          <Alert
            message="Dados incorretos:"
            description={errorMessage}
            type="error"
          />
        )}
        <br />
        <Portal
          id={portalId}
          defaultData={
            portalId
              ? data?.data?.filter((item) => item.id === portalId)[0]
              : updatedData
          }
          setData={(values) => setUpdatedData(values)}
        />
      </Modal>
    </>
  );
};

export default ListPortals;
