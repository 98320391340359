/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import styled from "./styled.module.scss";
import moment from "moment-timezone";
import Employer from "./index";
import { useRest } from "../../../services/http";
import { Popconfirm, message, Modal, Button, Alert } from "antd";
import { Table } from "antd";
import { useAuth } from "../../../providers/auth";
import { useParams } from "react-router-dom";
import { getCustomerID } from "../../../util/jwtUtil";

const ListEmployers = () => {
  const {
    post,
    put,
    remove,
    data: dataCreate,
    loading,
    status,
    message: messageEmployer,
  } = useRest();
  const { get, data } = useRest();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employerId, setEmployerId] = useState(0);
  const [updatedData, setUpdatedData] = useState({ country: "Brasil" });
  const { user } = useAuth();
  let { id } = useParams();
  if (!id) id = getCustomerID(user.access_token);

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    get("customerListEmployer", {
      page: tablePagination.current,
      id: id,
    });
  }, [tablePagination?.current, status]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const confirm = (id) => {
    remove("removeCustomerEmployer", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (status?.success) {
      setUpdatedData({ country: "Brasil" });
      setIsModalOpen(false);
      message.success(messageEmployer);
    } else if (status?.error) {
      message.error(messageEmployer);
    }
  }, [status]);

  const errorMessage = useMemo(() => {
    if (dataCreate) {
      if (Object.values(dataCreate)?.length > 0) {
        return Object.values(dataCreate).map((item) => (
          <>
            {item}
            <br />
          </>
        ));
      } else {
        return messageEmployer;
      }
    }
  }, [dataCreate]);

  const columns = [
    {
      title: "Nome do Empregador",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Data de Início",
      dataIndex: "start_date",
      key: "start_date",
      render: (start_date) => moment(start_date).format("DD/MM/YYYY"),
    },
    {
      title: "Data de Saída",
      dataIndex: "end_date",
      key: "end_date",
      render: (end_date) =>
        end_date ? moment(end_date).format("DD/MM/YYYY") : null,
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          <a className={styled.icon}>
            <i
              className="ri-edit-fill"
              title="Editar Empregador"
              onClick={() => showModal(record.id)}
            />
          </a>

          <Popconfirm
            title={"Deseja excluir: " + record.company_name + "?"}
            okText="Confirmar"
            cancelText="Cancelar"
            onConfirm={() => confirm(record.id)}
          >
            <a className={styled.icon}>
              <i
                className={styled.iconDelete + " ri-delete-bin-fill"}
                title="Deletar Empregador"
              />
            </a>
          </Popconfirm>
        </>
      ),
    },
  ];

  const showModal = (id) => {
    setEmployerId(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    !employerId
      ? post("createCustomerEmployer", {
          ...updatedData,
          customer_id: parseInt(id),
        })
      : put("updateCustomerEmployer", { id: employerId }, { ...updatedData });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setUpdatedData({ country: "Brasil" });
  };

  return (
    <>
      <br />
      <Button
        type="primary"
        onClick={() => showModal()}
        className="hp-bg-black-100 hp-border-color-black-100 hp-hover-bg-black-80 hp-hover-border-color-black-80"
      >
        Adicionar
      </Button>
      <br />
      <br />
      <Table
        className={styled.table}
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={data?.data}
        pagination={tablePagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        size={15}
      />
      <Modal
        title={!employerId ? "Adicionar Empregador" : "Editar Empregador"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        okText={!employerId ? "Adicionar" : "Editar"}
      >
        {status.error && (
          <Alert
            message="Dados incorretos:"
            description={errorMessage}
            type="error"
          />
        )}
        <br />
        <Employer
          id={employerId}
          defaultData={
            employerId
              ? data?.data?.filter((item) => item.id === employerId)[0]
              : updatedData
          }
          setData={(values) => setUpdatedData(values)}
        />
      </Modal>
    </>
  );
};

export default ListEmployers;
