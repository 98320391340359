/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { BrazilianStates } from "../../enums/brazilianStates";
import { useRest } from "../../services/http";
import {
  Popconfirm,
  message,
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  Card,
} from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { Permissions, hasPermission } from "../../router/permissions";

const ClientList = () => {
  const { get, loading, data, status } = useRest();
  const [form] = useForm();
  const [filterParams, setFilterParams] = useState({
    name: "",
    institution_name: "",
    coren: "",
  });

  const { user } = useAuth();

  const {
    remove: removeCustomer,
    status: removeStatus,
    message: removeMessage,
  } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));

  useEffect(() => {
    get("clientList", {
      page: tablePagination.current,
      ...filterParams,
    });
  }, [tablePagination?.current]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const filter = () => {
    get("clientList", { page: 1, ...filterParams });

    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const resetFilter = () => {
    const params = { name: "", institution_name: "", coren: "" };

    setFilterParams(params);
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
    form.resetFields();
    get("clientList", {
      page: 1,
      ...params,
    });
  };

  const confirm = (id) => {
    removeCustomer("removeCustomer", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (removeStatus.success) {
      get("clientList", { page: tablePagination.current });
      message.success(removeMessage);
      form.resetFields();
      setFilterParams({
        name: "",
        institution_name: "",
        coren: "",
      });
    }
    if (removeStatus.error) {
      message.error(removeMessage);
    }
  }, [removeStatus]);

  const columns = [
    {
      title: "Nome",
      dataIndex: ["user", "name"],
      key: "name",
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
      sortDirections: ["ascend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "País",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
      sortDirections: ["ascend"],
    },
    {
      title: "Estado",
      dataIndex: "uf",
      key: "uf",
      sorter: (a, b) => a.uf.localeCompare(b.uf),
      sortDirections: ["ascend"],
    },
    {
      title: "E-mail",
      dataIndex: ["user", "email"],
      key: "email",
      sorter: (a, b) => a.user.email.localeCompare(b.user.email),
      sortDirections: ["ascend"],
    },
    {
      title: "Telefone",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
      sortDirections: ["ascend"],
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          <Link to={"/show-client/" + record.id} className={styled.icon}>
            <i className="ri-eye-fill" title="Ver Cliente" />
          </Link>

          {hasPermission(user, Permissions.cadastrar_clientes) && (
            <Link to={"/view-client/" + record.id} className={styled.icon}>
              <i className="ri-edit-fill" title="Editar Cliente" />
            </Link>
          )}
          {hasPermission(user, Permissions.remover_clientes) && (
            <Popconfirm
              title={
                "Tem certeza que deseja deletar o cliente: " + record.user.name
              }
              onConfirm={() => confirm(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Cliente"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Card>
        <Form layout="vertical" autoComplete="off" form={form}>
          <Row
            justify="space-between"
            align="center"
            gutter={{ sm: 16, xs: 10 }}
          >
            <Col xs={24} sm={7}>
              <Form.Item
                label="Nome"
                name="name"
                value={filterParams.name}
                onChange={(e) =>
                  setFilterParams({ ...filterParams, name: e.target.value })
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={7}>
              <Form.Item
                label="Estado do COREN"
                name="uf-coren"
                value={filterParams.coren}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Estado do COREN"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .indexOf(
                        input
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                      ) >= 0
                  }
                  onChange={(value) =>
                    setFilterParams({
                      ...filterParams,
                      coren: value,
                    })
                  }
                >
                  {UFOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={7}>
              <Form.Item
                label="Nome da instituição de Ensino"
                name="institution_name"
                value={filterParams.institution_name}
                onChange={(e) =>
                  setFilterParams({
                    ...filterParams,
                    institution_name: e.target.value,
                  })
                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={3}>
              <Button
                style={{ margin: "30px 0 5px" }}
                type="primary"
                htmlType="submit"
                onClick={() => filter()}
              >
                Buscar
              </Button>
              <br />
              <a href="#" onClick={() => resetFilter()}>
                Limpar busca
              </a>
            </Col>
          </Row>
        </Form>
        <br />
        <Table
          className={styled.table}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.data}
          pagination={tablePagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          size={15}
        />
      </Card>
    </Wrapper>
  );
};

export default ClientList;
