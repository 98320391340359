/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Form, Input, Button, Alert, message } from "antd";
import { useEffect, useState } from "react";
import { useRest } from "../../services/http";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getRole,
  getName,
  getCustomerID,
  getPermissions,
} from "../../util/jwtUtil";
import { getExpiresDate } from "../../util/expireDate";
import { useAuth } from "../../providers/auth";

export default function ResetPassword() {
  const [form] = Form.useForm();
  const { setUser } = useAuth();
  const [userName, setUsername] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.username) {
      message.error("Usuário não encontrado");
      navigate("/login");
    }
    setUsername(location.state?.username);
  }, [userName]);

  const {
    post: postChangePassword,
    status,
    loading,
    message: changePasswordMessage,
    data,
  } = useRest();

  const submit = async () => {
    const loginData = {
      username: userName,
      password: form.getFieldValue("password"),
      password_confirmation: form.getFieldValue("passwordConfirmation"),
    };
    await postChangePassword("changePassword", loginData);
  };

  useEffect(() => {
    if (status.success) {
      const isEmployee = getRole(data?.access_token) !== 1;

      setUser({
        access_token: data?.access_token,
        token_type: data?.token_type,
        expires_in: data?.expires_in,
        expiresDate: getExpiresDate(data?.expires_in),
        role: getRole(data?.access_token),
        username: getName(data?.access_token),
        customerID: getCustomerID(data?.access_token) || "",
        isEmployee,
        permissions: getPermissions(data?.access_token),
      });

      const from = location.state?.from?.pathname
        ? location.state?.from?.pathname
        : isEmployee
        ? "/dashboard"
        : "/flow-list";

      navigate(from);
    }
  }, [status]);

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <Col lg={32} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={12}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            <h1 className="hp-mb-sm-0">Alterar senha</h1>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              form={form}
            >
              <Form.Item
                label="Senha:"
                className="hp-mb-8"
                name="password"
                validateStatus={status?.error ? "error" : ""}
                rules={[
                  {
                    required: true,
                    min: 6,
                    message: "Senha deve ter ao menos 6 caracteres!",
                  },
                ]}
              >
                <Input.Password id="password" />
              </Form.Item>
              <Form.Item
                label="Confirmar senha:"
                className="hp-mb-8"
                name="passwordConfirmation"
                validateStatus={status?.error ? "error" : ""}
                rules={[
                  {
                    required: true,
                    min: 6,
                    message:
                      "Confirmação de senha deve ter ao menos 6 caracteres!",
                  },
                ]}
              >
                <Input.Password id="confirmationPassword" />
              </Form.Item>
              {status?.error && (
                <Alert
                  message={changePasswordMessage || "Erro de validação"}
                  type="error"
                  closable
                />
              )}
              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={submit}
                  loading={loading}
                >
                  Confirmar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
