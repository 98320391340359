import { Form, Input, Row, Col, Checkbox, Select } from "antd";
import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";

const ComplementData = ({ setData, defaultData, isEditable }) => {
  const [form] = Form.useForm();
  const [nameChange, setNameChange] = useState(!!defaultData.maiden_name);

  const [formValues, setFormValues] = useState({
    passport_number: defaultData.passport_number || "",
    ssn: defaultData.ssn || "",
    mothers_maiden_name: defaultData.mothers_maiden_name || "",
    maiden_name: defaultData.maiden_name || "",
    before_surname: defaultData.before_surname || "",
    last_time_used_maiden_name: defaultData.last_time_used_maiden_name || "",
    skin_color: defaultData.skin_color || null,
    weight_mt: defaultData.weight_mt || null,
    height_kg: defaultData.height_kg || null,
    hair_color: defaultData.hair_color || null,
    eyes_color: defaultData.eyes_color || null,
    childhood_nickname: defaultData.childhood_nickname || "",
    mother_middle_name: defaultData.mother_middle_name || "",
    childhood_friend_name: defaultData.childhood_friend_name || "",
    mother_birthday: defaultData.mother_birthday || "",
    father_birthday: defaultData.father_birthday || "",
  });

  useEffect(() => {
    setData(formValues);
  }, [formValues, setData]);

  useEffect(() => {
    if (!nameChange) {
      setFormValues({
        ...formValues,
        maiden_name: "",
        before_surname: "",
        last_time_used_maiden_name: "",
      });
      form.setFieldValue("maiden_name", "");
      form.setFieldValue("before_surname", "");
      form.setFieldValue("last_time_used_maiden_name", "");
    }
  }, [nameChange]);

  return (
    <Form form={form} layout="vertical" disabled={!isEditable}>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Passaporte"
            name="cep"
            initialValue={formValues.passport_number}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                passport_number: e.target.value,
              })
            }
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Social Security Number - SSN"
            name="ssn"
            initialValue={formValues.ssn}
            onChange={(e) =>
              setFormValues({ ...formValues, ssn: e.target.value })
            }
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={8}>
          <Form.Item
            label="Nome de Solteira da mãe"
            name="mothers_maiden_name"
            initialValue={formValues.mothers_maiden_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                mothers_maiden_name: e.target.value,
              })
            }
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={24}>
          <Form.Item>
            <Checkbox
              onChange={() => setNameChange(!nameChange)}
              checked={nameChange}
            >
              Já houve alteração de nome?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {nameChange && (
        <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Nome de Solteira"
              name="maiden_name"
              initialValue={formValues.maiden_name}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  maiden_name: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Sobrenome anterior"
              name="before_surname"
              initialValue={formValues.before_surname}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  before_surname: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label={
                <label style={{ whiteSpace: "nowrap", fontSize: 12 }}>
                  Última vez que usou nome de solteira
                </label>
              }
              name="last_time_used_maiden_name"
              initialValue={formValues.last_time_used_maiden_name}
              help="Apróximadamente"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  last_time_used_maiden_name: e.target.value,
                })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Raça"
            name="country"
            initialValue={formValues.skin_color}
          >
            <Select
              showSearch
              allowClear
              placeholder="Raça"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, skin_color: value })
              }
            >
              <Select.Option key="A">Asiático (A)</Select.Option>
              <Select.Option key="B">Negro (B)</Select.Option>
              <Select.Option key="I">Indiano (I)</Select.Option>
              <Select.Option key="W">Branco (W)</Select.Option>
              <Select.Option key="U">Desconhecido (U)</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Cor dos olhos"
            name="eyes_color"
            initialValue={formValues.eyes_color}
          >
            <Select
              showSearch
              allowClear
              placeholder="Cor dos olhos"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, eyes_color: value })
              }
            >
              <Select.Option key="BLK">Preto (BLK)</Select.Option>
              <Select.Option key="BLU">Azul (BLU)</Select.Option>
              <Select.Option key="BRO">Marrom (BRO)</Select.Option>
              <Select.Option key="GRY">Cinza (GRY)</Select.Option>
              <Select.Option key="GRN">Verde (GRN)</Select.Option>
              <Select.Option key="HAZ">Cor de Avelã (HAZ)</Select.Option>
              <Select.Option key="MAR">
                Castanho avermelhado (MAR)
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Cor do cabelo"
            name="hair_color"
            initialValue={formValues.hair_color}
          >
            <Select
              showSearch
              allowClear
              placeholder="Cor do cabelo"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, hair_color: value })
              }
            >
              <Select.Option key="BLD">Loiro (BLD)</Select.Option>
              <Select.Option key="BLK">Preto (BLK)</Select.Option>
              <Select.Option key="BLU">Azul (BLU)</Select.Option>
              <Select.Option key="BRO">Marrom (BRO)</Select.Option>
              <Select.Option key="GRY">Cinza (GRY)</Select.Option>
              <Select.Option key="GRN">Verde (GRN)</Select.Option>
              <Select.Option key="HAZ">Cor de Avelã (HAZ)</Select.Option>
              <Select.Option key="ONG">Alaranjado (ONG)</Select.Option>
              <Select.Option key="PLE">Roxo (PLE)</Select.Option>
              <Select.Option key="PNK">Rosa (PNK)</Select.Option>
              <Select.Option key="RED">Vermelho (RED)</Select.Option>
              <Select.Option key="WHI">Branco (WHI)</Select.Option>
              <Select.Option key="XXX">Outro (XXX)</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Altura em metros"
            name="weight_mt"
            initialValue={String(formValues.weight_mt).replace(".", ",")}
            help={
              String(formValues.weight_mt).length >= 3
                ? "Feets " + Number(formValues.weight_mt * 3.28084).toFixed(2)
                : ""
            }
          >
            <InputMask
              mask="9,99"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  weight_mt: e.target.value
                    .replace(/_|-|\./g, "")
                    .replace(/[^\d]?![,]![_]/g, "")
                    .replace(",", "."),
                })
              }
            >
              <Input />
            </InputMask>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Peso em KG"
            name="height_kg"
            initialValue={formValues.height_kg}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                height_kg: e.target.value
                  .replace(/_|-|\./g, "")
                  .replace(/[^\d]?![,]![_]/g, "")
                  .replace(",", "."),
              })
            }
            help={
              String(formValues.weight_mt).length >= 3
                ? "Feets " + Number(formValues.height_kg * 2.20462).toFixed(2)
                : ""
            }
          >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Nome do seu melhor amigo ou amiga na infância?"
            name="childhood_friend_name"
            initialValue={formValues.childhood_friend_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                childhood_friend_name: e.target.value,
              })
            }
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label="Nome do meio da sua mãe?"
            name="mother_middle_name"
            initialValue={formValues.mother_middle_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                mother_middle_name: e.target.value,
              })
            }
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Qual seu apelido na infância?"
            name="childhood_nickname"
            initialValue={formValues.childhood_nickname}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                childhood_nickname: e.target.value,
              })
            }
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Data do aniversário da mãe?"
            name="mother_birthday"
            initialValue={formValues.mother_birthday}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                mother_birthday: e.target.value,
              })
            }
          >
            <Input type="date" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Data do aniversário do pai?"
            name="father_birthday"
            initialValue={formValues.father_birthday}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                father_birthday: e.target.value,
              })
            }
            rules={[
              {
                required: false,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ComplementData;
