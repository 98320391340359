import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";

export default function MenuLogo(props) {
  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-center">
      <Link
        to="/"
        onClick={props.onClose}
        className="hp-position-relative hp-d-flex"
      >
        <img className="hp-logo" src={logo} alt="Brazilian Nurse Abroad" />
      </Link>
    </div>
  );
}
