/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { message, Row, Col, Spin, Popconfirm, Input, Space } from "antd";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { useParams } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { Permissions, hasPermission } from "../../router/permissions";

const Attachments = ({ update }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const [attachments, setAttachments] = useState({});
  const [isChanging, setIsChanging] = useState(null);
  const [newName, setNewName] = useState("");

  const {
    get: getAttachments,
    loading: loadingAttachments,
    status: statusGetAttachments,
    data: dataAttachments,
  } = useRest();

  const {
    put: renameAttachment,
    remove: deleteAttachment,
    status: statusAttachment,
    message: messageAttachment,
  } = useRest();

  useEffect(() => {
    getAttachments("getAttachments", { id });
  }, [update]);

  useEffect(() => {
    if (statusGetAttachments.success) {
      setAttachments(dataAttachments);
    } else if (statusGetAttachments.error) {
      message.error("Ocorreu um erro ao buscar os anexos. Tente novamente!");
    }
  }, [statusGetAttachments]);

  useEffect(() => {
    if (statusAttachment.success) {
      getAttachments("getAttachments", { id });
      message.success(messageAttachment);
      setIsChanging(null);
    } else if (statusAttachment.error) {
      message.error(messageAttachment);
    }
  }, [statusAttachment]);

  const removeAttachment = (id) => {
    deleteAttachment("attachment", { id });
  };

  const downloadFile = async (filePath) => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}${filePath}`, {
      headers: {
        Authorization: `${"Bearer " + user?.access_token}`,
      },
    });
    const response = await data.blob();
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = filePath.replace("attachment/", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const changeName = (id, value) => {
    if (value) {
      renameAttachment("attachment", { id }, { alias: value });
    }

    setIsChanging(null);
  };

  return (
    <>
      <h5 className="hp-mt-sm-16 hp-mt-16">Anexos</h5>

      {loadingAttachments ? (
        <Row type="flex" align="middle" justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : attachments.length > 0 ? (
        attachments?.map((item) => (
          <p>
            {isChanging !== item.id && (
              <a href="#" onClick={() => downloadFile(item.path)}>
                {item.alias ? item.alias : item.name}
              </a>
            )}

            {isChanging === item.id && user.isEmployee && (
              <>
                <Space>
                  <Input
                    placeholder={item.name}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                  <a
                    className={styled.icon}
                    onClick={() => changeName(item.id, newName)}
                  >
                    <i className="ri-check-fill" title="Editar nome do anexo" />
                  </a>
                </Space>
              </>
            )}

            {hasPermission(user, Permissions.cadastrar_interacoes) &&
              user.isEmployee &&
              isChanging !== item.id && (
                <>
                  <br />
                  <a
                    className={styled.icon}
                    onClick={() => setIsChanging(item.id)}
                  >
                    <i className="ri-edit-fill" title="Editar nome do anexo" />
                  </a>
                </>
              )}

            {hasPermission(user, Permissions.remover_interacoes) &&
              user.isEmployee && (
                <>
                  <Popconfirm
                    title={
                      "Tem certeza que deseja deletar o anexo: " + item.name
                    }
                    onConfirm={() => removeAttachment(item.id)}
                    okText="Confirmar"
                    cancelText="Cancelar"
                  >
                    <a className={styled.icon}>
                      <i
                        className={styled.iconDelete + " ri-delete-bin-fill"}
                        title="Deletar anexo"
                      />
                    </a>
                  </Popconfirm>
                  <br />
                  <br />
                </>
              )}
          </p>
        ))
      ) : (
        <p>Não existem anexos</p>
      )}
    </>
  );
};

export default Attachments;
