/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import {
  Form,
  Checkbox,
  Button,
  Card,
  message,
  Row,
  Col,
  Select,
  Spin,
  Input,
  Upload,
  Popconfirm,
  Table,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useAuth } from "../../providers/auth";
import { StatusType } from "./type";
import moment from "moment-timezone";
import { Permissions, hasPermission } from "../../router/permissions";
import Attachments from "./attachments";
import Messages from "./messages";
import { showEmbededHTML } from "../../util/ckeditor";

const Interaction = () => {
  const [form] = Form.useForm();
  const { user } = useAuth();
  const { id } = useParams();
  const { post: postInteractionMessage, status, loading, data } = useRest();
  const [fileList, setFileList] = useState([]);
  const [optionsInstruction, setOptionsInstruction] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [formValues, setFormValues] = useState({
    is_private: 0,
    flow_id: id,
    notify: 0,
  });

  const [flowHandle, setFlowHandle] = useState({});

  const {
    put: flowUpdate,
    status: flowUpdateStatus,
    loading: flowUpdateLoading,
    data: flowUpdateData,
  } = useRest();

  const { get: instructions } = useRest();

  const {
    get: flow,
    status: flowStatus,
    loading: flowLoading,
    data: flowData,
  } = useRest();

  const {
    get: fullInstructionsList,
    loading: fullInstructionsListLoading,
    data: fullInstructionsListData,
  } = useRest();

  const {
    post: flowUpdateInstruction,
    status: flowUpdateInstructionStatus,
    message: flowUpdateInstructionMessage,
    loading: flowUpdateInstructionLoading,
  } = useRest();

  const {
    remove: removeInstructionFromFlow,
    status: removeInstructionFromFlowStatus,
    message: removeInstructionFromFlowMessage,
  } = useRest();

  const { get: fullResponsableList, data: fullDataResponsableList } = useRest();

  useEffect(() => {
    if (removeInstructionFromFlowStatus.success) {
      flow("flow", {
        id: id,
      });
      message.success(removeInstructionFromFlowMessage);
    }
    if (removeInstructionFromFlowStatus.error) {
      message.error(removeInstructionFromFlowMessage);
    }
  }, [removeInstructionFromFlowStatus]);

  useEffect(() => {
    const data = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id}>{item.description}</Select.Option>
    ));
    setResponsables(data);
  }, [fullDataResponsableList]);

  useEffect(() => {
    if (flowStatus.success) {
      form.setFieldValue("responsavel", String(flowData?.assignee_id));

      setFlowHandle({
        ...flowHandle,
        start_date: flowData?.start_date,
        end_date: flowData?.end_date,
      });
    }
  }, [flowData, flowStatus]);

  useEffect(() => {
    instructions("instructions", {
      id: id,
    });
    flow("flow", {
      id: id,
    });
    fullResponsableList("fullResponsableList");
  }, [id]);

  useEffect(() => {
    if (!user.isEmployee) {
      setFormValues({ ...formValues, user_id: user.customerID });
    }
  }, [user]);

  const submit = () => {
    if (!formValues.message) {
      message.error("Mensagem é obrigatória!");
      return;
    }

    const formData = new FormData();
    formData.append("flow_id", formValues.flow_id);
    formData.append("is_private", formValues.is_private);
    formData.append("notify", formValues.notify);
    formData.append("message", formValues.message);
    fileList.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    postInteractionMessage("postInteractionMessage", formData);
  };

  const submitFlow = () => {
    flowUpdate("flow", { id: id }, { ...flowHandle });
  };

  const getResponsableById = (responsableId) =>
    fullDataResponsableList?.find(
      (responsable) => responsable.id === parseInt(responsableId)
    );

  useEffect(() => {
    if (status?.success) {
      message.success("Mensagem enviada com sucesso!");
      form.resetFields();
      setFileList([]);
      setFormValues({
        is_private: 0,
        flow_id: id,
        notify: 0,
      });
      flow("flow", {
        id: id,
      });
    }
    if (status?.error) {
      data
        ? message.error(Object.values(data)[0])
        : message.error("Ocorreu um erro na criação da mensagem!");
    }
  }, [status, form]);

  useEffect(() => {
    if (flowUpdateStatus?.success) {
      message.success("Interação atualizada com sucesso!");
    }
    if (flowUpdateStatus?.error) {
      message.error(Object.values(flowUpdateData)[0]);
    }
  }, [flowUpdateStatus]);

  const props = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    onChange(info) {
      const arrayFiles = info.fileList.map((file) =>
        file.originFileObj ? file.originFileObj : file
      );
      setFileList(arrayFiles);
    },
    fileList: fileList,
  };

  useEffect(() => {
    fullInstructionsList("fullInstructionsList");
  }, []);

  useEffect(() => {
    let instructionArr = fullInstructionsListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));

    setOptionsInstruction(instructionArr);
  }, [fullInstructionsListData]);

  const pushInstruction = async () => {
    const isFormValid = await form.validateFields();

    if (isFormValid) {
      await flowUpdateInstruction("flowUpdateInstruction", {
        flow_id: id,
        instructions: form.getFieldValue("instructions") || [],
      });
    }
  };

  useEffect(() => {
    if (flowUpdateInstructionStatus?.success) {
      message.success(flowUpdateInstructionMessage);
      form.resetFields();
      flow("flow", {
        id: id,
      });
    }

    if (flowUpdateInstructionStatus?.error) {
      message.error(flowUpdateInstructionMessage);
    }
  }, [flowUpdateInstructionStatus, form, flowUpdateInstructionMessage]);

  const confirm = (id) => {
    removeInstructionFromFlow("removeInstructionFromFlow", { id: id });
  };

  const columns = [
    {
      title: "Instruções",
      dataIndex: ["instruction", "title"],
      key: "instruction.title",
    },
  ];

  user.isEmployee &&
    columns.push({
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          {hasPermission(user, Permissions.remover_interacoes) && (
            <Popconfirm
              title={
                "Tem certeza que deseja deletar a instrução: " +
                record.instruction.title +
                "?"
              }
              onConfirm={() => confirm(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Instrução"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    });

  return (
    <Wrapper>
      <Row align="flex-start" justify="center" gutter={[32, 0]}>
        <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
          <Card>
            {flowLoading ? (
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Spin />
                </Col>
              </Row>
            ) : (
              <div className={styled.instructions}>
                {user.isEmployee && (
                  <>
                    <h5>{flowData?.customer?.user?.name}</h5>
                    <h5>{flowData?.step?.service?.title}</h5>
                    <hr />
                    <br />
                  </>
                )}
                <h4>{flowData?.step?.title}</h4>
                <h5>{flowData?.step?.description}</h5>
                <hr />
                <br />

                {flowStatus.success &&
                  flowData?.flow_instruction.length > 0 && (
                    <>
                      <Table
                        className={styled.table}
                        columns={columns}
                        rowKey={(record) => record?.id}
                        dataSource={flowData?.flow_instruction}
                        pagination={false}
                        loading={flowLoading}
                        expandable={{
                          expandedRowRender: (record) => (
                            <div className="editorDisabled">
                              <CKEditor
                                editor={Editor}
                                data={record.instruction.description}
                                disabled={true}
                              />
                            </div>
                          ),
                          onExpand: () => showEmbededHTML(),
                        }}
                      />
                      <br />
                    </>
                  )}
              </div>
            )}
            {fullInstructionsListLoading ? (
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Spin />
                </Col>
              </Row>
            ) : user.isEmployee &&
              hasPermission(user, Permissions.cadastrar_interacoes) ? (
              <Form
                layout="vertical"
                name="basic"
                className="hp-mt-sm-16 hp-mt-16"
                form={form}
              >
                <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
                  <Col xs={24} sm={24} flex="center">
                    <Form.Item
                      name="instructions"
                      label="Instruções"
                      rules={[
                        {
                          required: true,
                          message: "Campo obrigatorio!",
                        },
                      ]}
                    >
                      <Select
                        name="instructions"
                        mode="multiple"
                        showSearch
                        allowClear
                        placeholder="Selecionar Instruções"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {optionsInstruction}
                      </Select>
                    </Form.Item>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      className={styled.stepButton}
                      loading={flowUpdateInstructionLoading}
                      onClick={pushInstruction}
                    >
                      Adicionar nova instrução
                    </Button>
                  </Col>
                </Row>
                <br />
              </Form>
            ) : null}
            {(!user.isEmployee && flowData?.status === "FIN") ||
            (user.isEmployee &&
              !hasPermission(user, Permissions.cadastrar_interacoes)) ? null : (
              <div className="ckeditor-min">
                <Form form={form} layout="vertical">
                  {user.isEmployee ? (
                    <CKEditor
                      data={formValues?.message || ""}
                      editor={Editor}
                      onChange={(events, editor) => {
                        const data = editor.getData();
                        setFormValues({
                          ...formValues,
                          message: data,
                        });
                      }}
                    />
                  ) : (
                    <Form.Item
                      name="mensagem"
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          message: e.target.value,
                        })
                      }
                    >
                      <Input.TextArea placeholder="Adicionar mensagem" />
                    </Form.Item>
                  )}

                  <div className={styled.editorBottom}>
                    <Upload {...props} className={styled.createButton}>
                      <Button icon={<UploadOutlined className="remix-icon" />}>
                        Anexar arquivos
                      </Button>
                    </Upload>
                    <Button
                      type="primary"
                      onClick={() => submit()}
                      loading={loading}
                      className={styled.sendButton}
                    >
                      Enviar
                    </Button>
                  </div>

                  {user.isEmployee && (
                    <div className={styled.editorBottom}>
                      <Checkbox
                        className={styled.checkbox}
                        checked={formValues?.notify}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            notify: e.target.checked ? 1 : 0,
                          })
                        }
                      >
                        Notificar cliente
                      </Checkbox>

                      <Checkbox
                        className={styled.checkbox}
                        checked={formValues?.is_private}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            is_private: e.target.checked ? 1 : 0,
                          })
                        }
                      >
                        Mensagem privada?
                      </Checkbox>
                    </div>
                  )}
                </Form>
              </div>
            )}
            {hasPermission(user, Permissions.visualizar_interacoes) && (
              <Messages
                update={flowData?.flow_interaction}
                onUpdate={() =>
                  flow("flow", {
                    id: id,
                  })
                }
                flowStatus={flowData?.status}
              />
            )}
          </Card>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
          <Card>
            {flowLoading ? (
              <Row type="flex" align="middle" justify="center">
                <Col>
                  <Spin />
                </Col>
              </Row>
            ) : (
              <>
                {!user.isEmployee && (
                  <>
                    <h5>Responsável</h5>
                    <p>
                      {getResponsableById(flowData?.assignee_id)?.description}
                    </p>

                    <h5>Status</h5>
                    <p>{StatusType[flowData?.status]}</p>

                    <h5>Data de início</h5>
                    <p>
                      {flowData?.start_date
                        ? moment(flowData?.start_date)
                            .tz("America/Sao_Paulo")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </p>

                    <h5>Data de conclusão</h5>
                    <p>
                      {flowData?.end_date
                        ? moment(flowData?.end_date)
                            .tz("America/Sao_Paulo")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </p>

                    <h5>Board of Nursing</h5>
                    <p>
                      {flowData?.state_bon
                        ? flowData?.state_bon
                        : "Não cadastrado."}
                    </p>
                  </>
                )}
                {flowData?.status && user.isEmployee && (
                  <Form layout="vertical" name="basic" form={form}>
                    <Form.Item label="Responsável" name="responsavel">
                      <Select
                        showSearch
                        allowClear
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                        placeholder="Responsável"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) =>
                          setFlowHandle({
                            ...flowHandle,
                            assignee_id: value,
                          })
                        }
                      >
                        {responsables}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Status"
                      name="status"
                      initialValue={flowData?.status}
                    >
                      <Select
                        showSearch
                        allowClear
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                        placeholder="Status"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) =>
                          setFlowHandle({ ...flowHandle, status: value })
                        }
                      >
                        <Select.Option key="NOT">Não Iniciado</Select.Option>
                        <Select.Option key="INP">Em Andamento</Select.Option>
                        <Select.Option key="FIN">Finalizado</Select.Option>
                        <Select.Option key="PEN">Pendente</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Prazo"
                      name="deadline_date"
                      initialValue={moment(flowData?.deadline_date)
                        .tz("America/Sao_Paulo")
                        .format("YYYY-MM-DD")}
                      onChange={(e) =>
                        setFlowHandle({
                          ...flowHandle,
                          deadline_date: e.target.value,
                        })
                      }
                    >
                      <Input
                        type="date"
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Data de início"
                      name="start_date"
                      initialValue={moment(flowData?.start_date)
                        .tz("America/Sao_Paulo")
                        .format("YYYY-MM-DD")}
                      onChange={(e) =>
                        setFlowHandle({
                          ...flowHandle,
                          start_date: e.target.value,
                        })
                      }
                    >
                      <Input
                        type="date"
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Data de conclusão"
                      name="end_date"
                      initialValue={moment(flowData?.end_date)
                        .tz("America/Sao_Paulo")
                        .format("YYYY-MM-DD")}
                      onChange={(e) =>
                        setFlowHandle({
                          ...flowHandle,
                          end_date: e.target.value,
                        })
                      }
                    >
                      <Input
                        type="date"
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Board of Nursing"
                      name="state_bon"
                      initialValue={flowData?.state_bon}
                      onChange={(e) =>
                        setFlowHandle({
                          ...flowHandle,
                          state_bon: e.target.value,
                        })
                      }
                    >
                      <Input
                        disabled={
                          !hasPermission(user, Permissions.cadastrar_fluxos)
                        }
                      />
                    </Form.Item>

                    <Button
                      type="primary"
                      disabled={
                        !hasPermission(user, Permissions.cadastrar_fluxos)
                      }
                      className={styled.createButton}
                      loading={flowUpdateLoading}
                      onClick={() => submitFlow()}
                    >
                      Salvar
                    </Button>
                  </Form>
                )}

                <Attachments update={flowData?.attachments} />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Interaction;
