/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Wrapper from "../../components/layout";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { Popconfirm, message, Form, Button, Input, Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import moment from "moment-timezone";

import { Table } from "antd";
import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { Permissions, hasPermission } from "../../router/permissions";

const EmployeeList = () => {
  const { get, loading, data, status } = useRest();
  const [form] = useForm();
  const [filterParams, setFilterParams] = useState({
    name: "",
  });
  const { user } = useAuth();

  const {
    remove: removeEmployee,
    status: removeStatus,
    message: removeMessage,
  } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    get("employeeList", {
      page: tablePagination.current,
      ...filterParams,
    });
  }, [tablePagination?.current]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const filter = () => {
    get("employeeList", { page: 1, ...filterParams });

    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const resetFilter = () => {
    const params = { name: "" };

    setFilterParams(params);
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
    form.resetFields();
    get("employeeList", {
      page: 1,
      ...params,
    });
  };

  const confirm = (id) => {
    removeEmployee("removeEmployee", { id: id });
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  useEffect(() => {
    if (removeStatus.success) {
      get("employeeList", { page: tablePagination.current });
      message.success(removeMessage);
      form.resetFields();
      setFilterParams({
        name: "",
      });
    }
    if (removeStatus.error) {
      message.error(removeMessage);
    }
  }, [removeStatus]);

  useEffect(() => {
    if (status?.error) {
      message.error(
        "Ocorreu um erro ao listar os funcionários. Tente novamente!"
      );
    }
  }, [status]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend"],
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      key: "created_at",
      render: (record) =>
        moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY - HH:mm"),
    },
    {
      title: "Atualizado em",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (record) =>
        moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY - HH:mm"),
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <>
          {hasPermission(user, Permissions.cadastrar_funcionarios) && (
            <Link to={"/view-employee/" + record.id} className={styled.icon}>
              <i className="ri-edit-fill" title="Editar Funcionário" />
            </Link>
          )}

          {hasPermission(user, Permissions.remover_funcionarios) && (
            <Popconfirm
              title={"Tem certeza que deseja deletar o usuário: " + record.name}
              onConfirm={() => confirm(record.id)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <a className={styled.icon}>
                <i
                  className={styled.iconDelete + " ri-delete-bin-fill"}
                  title="Deletar Funcionário"
                />
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Card>
        <Form layout="vertical" autoComplete="off" form={form}>
          <Row
            justify="space-between"
            align="center"
            gutter={{ sm: 16, xs: 10 }}
          >
            <Col xs={24} sm={21}>
              <Form.Item
                label="Nome"
                name="name"
                value={filterParams.name}
                onChange={(e) =>
                  setFilterParams({ ...filterParams, name: e.target.value })
                }
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={3}>
              <Button
                style={{ margin: "30px 0 5px" }}
                type="primary"
                htmlType="submit"
                onClick={() => filter()}
              >
                Buscar
              </Button>
              <br />
              <a href="#" onClick={() => resetFilter()}>
                Limpar busca
              </a>
            </Col>
          </Row>
        </Form>
        <br />
        <Table
          className={styled.table}
          columns={columns}
          rowKey={(record) => record?.id}
          dataSource={data?.data}
          pagination={tablePagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          size={15}
        />
      </Card>
    </Wrapper>
  );
};

export default EmployeeList;
