/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  message,
  Row,
  Col,
  Spin,
  Pagination,
  Popconfirm,
  Button,
  Input,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { useParams } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import moment from "moment-timezone";
import { getUserID } from "../../util/jwtUtil";
import { hasPermission, Permissions } from "../../router/permissions";

const Messages = ({ update, onUpdate, flowStatus }) => {
  const { user } = useAuth();
  const { id } = useParams();
  const [isChanging, setIsChanging] = useState(null);
  const [newMessage, setNewMessage] = useState("");

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  const { get, loading, data } = useRest();
  const { put, remove, status, message: messageInteraction } = useRest();
  const { postID: resendInteraction, status: resendInteractionStatus } =
    useRest();

  const deleteInteraction = (interactionId) => {
    remove("interaction", { id: interactionId });
  };

  useEffect(() => {
    get("flowInteractions", {
      page: tablePagination.current,
      flowId: id,
      pageSize: tablePagination.pageSize,
    });
  }, [tablePagination?.current, update]);

  useEffect(() => {
    if (status.success) {
      message.success(messageInteraction);
      setIsChanging(null);
      setNewMessage("");
      onUpdate();
      get("flowInteractions", {
        page: tablePagination.current,
        flowId: id,
        pageSize: tablePagination.pageSize,
      });
    } else if (status.error) {
      message.error(messageInteraction);
    }
  }, [status]);

  useEffect(() => {
    if (resendInteractionStatus.success) {
      message.success("O cliente foi re-notificado!");
    } else if (resendInteractionStatus.error) {
      message.error(
        "Ocorreu um erro ao re-notificar o cliente. Tente novamente!"
      );
    }
  }, [resendInteractionStatus]);

  const paginationChange = (current) => {
    setTablePagination({ ...tablePagination, current: current });
  };

  const resend = (interactionId) => {
    resendInteraction("resendInteraction", { id: interactionId });
  };

  const canEditOrExclude = (item, permission) => {
    if (
      getUserID(user.access_token) === item.user_id &&
      isChanging !== item.id &&
      hasPermission(user, permission)
    ) {
      if (user.isEmployee) {
        return true;
      } else {
        if (flowStatus === "FIN") {
          return false;
        } else {
          return true;
        }
      }
    }

    return false;
  };

  const changeMessage = (item, message) => {
    if (message) {
      put(
        "interaction",
        { id: item.id },
        {
          ...item,
          message,
        }
      );
    }

    setNewMessage("");
    setIsChanging(null);
  };

  return loading ? (
    <Row type="flex" align="middle" justify="center">
      <Col>
        <Spin />
      </Col>
    </Row>
  ) : (
    <>
      {data?.data?.length > 0 && (
        <div className={styled.chatWrapper}>
          {Object.values(data?.data)?.map((item, index) => (
            <div
              className={`${styled.chatCard} ${
                Boolean(item.is_private) ? styled.private : ""
              }`}
              key={index}
            >
              <div className={styled.heading}>
                <p>{item.user?.name || "Usuário"}</p>
                <div>
                  {canEditOrExclude(item, Permissions.cadastrar_interacoes) && (
                    <a
                      className={styled.icon}
                      onClick={() => setIsChanging(item.id)}
                    >
                      <i className="ri-edit-fill" title="Editar interação" />
                    </a>
                  )}

                  {canEditOrExclude(item, Permissions.remover_interacoes) && (
                    <Popconfirm
                      title={
                        "Tem certeza que deseja deletar a interação: " +
                        item.message.replace(/(<([^>]+)>)/gi, "") +
                        "?"
                      }
                      onConfirm={() => deleteInteraction(item.id)}
                      okText="Confirmar"
                      cancelText="Cancelar"
                    >
                      <a className={styled.icon}>
                        <i
                          className={styled.iconDelete + " ri-delete-bin-fill"}
                          title="Deletar interação"
                        />
                      </a>
                    </Popconfirm>
                  )}

                  {getUserID(user.access_token) === item.user_id &&
                    user.isEmployee &&
                    !item.is_private &&
                    hasPermission(user, Permissions.cadastrar_interacoes) && (
                      <a
                        className={styled.icon}
                        onClick={() => resend(item.id)}
                      >
                        <i
                          className="ri-mail-send-fill"
                          title="Renotificar Cliente"
                        />
                      </a>
                    )}
                </div>
              </div>
              {isChanging === item.id ? (
                <>
                  {user.isEmployee ? (
                    <CKEditor
                      data={item.message || ""}
                      onChange={(events, editor) => {
                        const data = editor.getData();
                        setNewMessage(data);
                      }}
                      editor={Editor}
                    />
                  ) : (
                    <Input.TextArea
                      defaultValue={item.message || ""}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                  )}

                  <br />

                  <Button onClick={() => changeMessage(item, newMessage)}>
                    Atualizar
                  </Button>
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <div
                    className={styled.cardDescription}
                    dangerouslySetInnerHTML={{ __html: item.message }}
                  />

                  <div className={styled.cardBottom}>
                    <span className={styled.privateTag}>
                      {Boolean(item.is_private) && "Essa mensagem é privada!"}
                    </span>

                    <span className={styled.date}>
                      Última atualização:
                      <br />
                      {moment(item.updated_at)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY - HH:mm")}
                    </span>
                  </div>
                  {item?.attachments?.length > 0 && (
                    <>
                      <hr />
                      <p style={{ padding: "0 10px" }}>
                        Anexos:{" "}
                        {item.attachments.map((attachment) => (
                          <p>
                            {attachment.alias
                              ? attachment.alias
                              : attachment.name}
                          </p>
                        ))}
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      )}

      {data?.last_page !== 1 && (
        <Pagination
          defaultCurrent={tablePagination.current}
          defaultPageSize={tablePagination.pageSize}
          total={data?.total}
          onChange={paginationChange}
        />
      )}
    </>
  );
};

export default Messages;
