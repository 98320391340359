import { useState, useEffect, useMemo } from "react";
import Wrapper from "../../components/layout";
import {
  Form,
  Input,
  Button,
  Space,
  message,
  Card,
  Select,
  Row,
  Col,
  Comment,
  List,
  Popconfirm,
  Modal,
} from "antd";
import moment from "moment-timezone";

import { SearchOutlined } from "@ant-design/icons";
import cepPromise from "cep-promise";
import { validate } from "gerador-validador-cpf";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import pt from "react-phone-input-2/lang/pt.json";
import { BrazilianStates } from "../../util/estadosMock";
import { Country } from "../../util/country";
import { useNavigate, useParams } from "react-router-dom";
import { Permissions, hasPermission } from "../../router/permissions";
import { useAuth } from "../../providers/auth";

const ViewProspect = () => {
  const { user } = useAuth();
  const [isChanging, setIsChanging] = useState(null);
  const [newName, setNewName] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const {
    put: updateProspectClient,
    status,
    message: updateMessage,
  } = useRest();
  const {
    postID: prospectAddLog,
    status: prospectAddLogStatus,
    loading: prospectAddLogLoading,
  } = useRest();

  const {
    get: prospectLog,
    data: prospectLogData,
    status: prospectLogStatus,
  } = useRest();

  const {
    put: putActivity,
    remove: removeActivity,
    status: statusActivity,
    message: messageActivity,
  } = useRest();

  const {
    get: getVerify,
    status: statusVerify,
    data: dataVerify,
    loading: loadingVerify,
  } = useRest();

  const [isDisabled, setIsDisabled] = useState(true);
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const {
    get: prospect,
    data: prospectData,
    status: prospectStatus,
  } = useRest();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({ country: "Brasil" });
  const [optionsResponsable, setOptionsResponsable] = useState([]);
  const [optionsService, setOptionsService] = useState([]);
  const { TextArea } = Input;

  const {
    get: fullServiceList,
    data: fullServiceListData,
    loading: fullServiceListLoading,
  } = useRest();
  const {
    get: fullResponsableList,
    data: fullDataResponsableList,
    loading: responsableListLoading,
  } = useRest();

  useEffect(() => {
    fullResponsableList("fullResponsableList");
    fullServiceList("fullServiceList");
  }, []);

  useEffect(() => {
    prospect("prospect", { id: id });
    prospectLog("prospectLog", { id: id });
  }, [id]);

  useEffect(() => {
    setFormValues(prospectData);
  }, [prospectData]);

  const editHandle = () => {
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    let responsableArr = fullDataResponsableList?.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.description}
      </Select.Option>
    ));

    setOptionsResponsable(responsableArr);
  }, [fullDataResponsableList]);

  useEffect(() => {
    let serviceArr = fullServiceListData?.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.title}
      </Select.Option>
    ));

    setOptionsService(serviceArr);
  }, [fullServiceListData]);

  useEffect(() => {
    if (statusActivity.success) {
      message.success(messageActivity);
      prospectLog("prospectLog", { id: id });
    } else if (statusActivity.error) {
      message.error(messageActivity);
    }
  }, [statusActivity]);

  const submit = () => {
    updateProspectClient("updateProspectClient", { id: id }, formValues);
  };

  const changeName = (prospectId, activityId, value) => {
    if (value) {
      putActivity(
        "prospectActivity",
        { prospectId, activityId },
        { description: value }
      );
    }

    setIsChanging(null);
  };

  useEffect(() => {
    if (status?.success) {
      message.success("Prospecção atualizada com sucesso!");
      setIsDisabled(true);
    } else if (status?.error) {
      message.error(updateMessage);
    }
  }, [status, form, updateMessage]);

  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf", address.state);
      form.setFieldValue("city", address.city);
      form.setFieldValue("street", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };

  const isBrasil = useMemo(() => {
    return formValues?.country === "Brasil";
  }, [formValues]);

  const UFBirthIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues?.uf_of_birth
  );

  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x?.uf === formValues?.uf
  );

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item?.uf}>{item.name}</Select.Option>
  ));

  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));

  const CityBirthOptions = BrazilianStates?.states[UFBirthIndex]?.citys.map(
    (item) => <Select.Option key={item}>{item}</Select.Option>
  );

  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  const submitLog = () => {
    setIsChanging(null);
    prospectAddLog("prospectLog", { id: id }, { description: comment });
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (prospectAddLogStatus.success) {
      form.resetFields();
      prospectLog("prospectLog", { id: id });
    }
  }, [prospectAddLogStatus]);

  const deleteProspect = (prospectId, activityId) => {
    removeActivity("prospectActivity", { prospectId, activityId });
  };

  useEffect(() => {
    if (statusVerify.success) {
      if (dataVerify) {
        setModalVisible(true);
      } else {
        navigate("/create-client", { state: formValues });
      }
    } else if (statusVerify.error) {
      navigate("/create-client", { state: formValues });
    }
  }, [statusVerify]);

  return (
    <Wrapper>
      {prospectStatus.success && (
        <Card>
          <Form form={form} layout="vertical">
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  name="name"
                  label="Nome completo"
                  initialValue={prospectData.name}
                  onBlur={(e) =>
                    setFormValues({ ...formValues, name: e.target.value })
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Nome completo"
                    showCount
                    maxLength={50}
                    name="name"
                    label="name"
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  name="email"
                  label="E-mail"
                  initialValue={prospectData.email}
                  onBlur={(e) =>
                    setFormValues({ ...formValues, email: e.target.value })
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="E-mail"
                    showCount
                    maxLength={100}
                    name="email"
                    label="email"
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={8} flex="center">
                <Form.Item
                  label="Data de nascimento"
                  name="birthdate"
                  initialValue={prospectData.birthdate}
                  onChange={(e) =>
                    setFormValues({ ...formValues, birthdate: e.target.value })
                  }
                >
                  <Input type="date" disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} flex="center">
                <Form.Item
                  label="CPF"
                  name="cpf"
                  initialValue={prospectData.cpf}
                  rules={[
                    {
                      validator(_, value) {
                        if (value && !validate(value)) {
                          return Promise.reject(new Error("CPF inválido!"));
                        }
                      },
                    },
                  ]}
                >
                  <InputMask
                    mask="999.999.999-99"
                    disabled={isDisabled}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        cpf: e.target.value.replace(/[^\d]/g, ""),
                      })
                    }
                  >
                    <Input />
                  </InputMask>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} flex="center">
                <Form.Item
                  label="Telefone"
                  name="phone_number"
                  initialValue={prospectData.phone_number}
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <PhoneInput
                    country={"br"}
                    disabled={isDisabled}
                    onChange={(value) =>
                      setFormValues({
                        ...formValues,
                        phone_number: "+" + value,
                      })
                    }
                    localization={pt}
                    enableSearch
                    inputClass={styled.phoneNumber}
                    defaultErrorMessage="Telefone é obrigatório"
                    isValid={(inputNumber) =>
                      inputNumber?.length > 0 ? true : false
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="RG"
                  name="rg"
                  onChange={(e) =>
                    setFormValues({ ...formValues, rg: e.target.value })
                  }
                  initialValue={prospectData.rg}
                >
                  <Input disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="Órgão Emissor / UF"
                  name="org-rg"
                  initialValue={prospectData.issued_by}
                  onChange={(e) =>
                    setFormValues({ ...formValues, issued_by: e.target.value })
                  }
                >
                  <Input disabled={isDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="Estado Civil"
                  name="marital_status"
                  initialValue={prospectData.marital_status}
                >
                  <Select
                    showSearch
                    disabled={isDisabled}
                    allowClear
                    placeholder="Estado Civil"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      setFormValues({
                        ...formValues,
                        marital_status: value,
                      })
                    }
                  >
                    <Select.Option key="SNG">Solteiro(a)</Select.Option>
                    <Select.Option key="MAR">Casado(a)</Select.Option>
                    <Select.Option key="SEP">Separado(a)</Select.Option>
                    <Select.Option key="DIV">Divorciado(a)</Select.Option>
                    <Select.Option key="WID">Viúvo(a)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="País"
                  name="country"
                  initialValue={prospectData.country}
                >
                  <Select
                    showSearch
                    disabled={isDisabled}
                    allowClear
                    placeholder="País"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) =>
                      setFormValues({ ...formValues, country: value })
                    }
                  >
                    {CountryOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                <Form.Item>
                  <Space>
                    <Form.Item
                      label={isBrasil ? "CEP" : "Código Postal"}
                      name="cep"
                      initialValue={prospectData.cep}
                    >
                      <InputMask
                        disabled={isDisabled}
                        mask={isBrasil ? "99999-999" : null}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            cep: e.target.value.replace(/[^\d]/g, ""),
                          })
                        }
                      >
                        <Input />
                      </InputMask>
                    </Form.Item>

                    {isBrasil && (
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        style={{ marginTop: "6px" }}
                        onClick={getAddress}
                      />
                    )}
                  </Space>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} flex="center">
                {isBrasil ? (
                  <Form.Item
                    label="Estado"
                    name="uf"
                    initialValue={prospectData.uf}
                  >
                    <Select
                      showSearch
                      allowClear
                      disabled={isDisabled}
                      placeholder="Estado"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                          ) >= 0
                      }
                      onChange={(value) =>
                        setFormValues({
                          ...formValues,
                          uf: value,
                          city: "",
                        })
                      }
                    >
                      {UFOptions}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Estado"
                    name="uf"
                    disabled={isDisabled}
                    initialValue={prospectData.uf}
                    onChange={(e) =>
                      setFormValues({ ...formValues, uf: e.target.value })
                    }
                    rules={[
                      {
                        required: true,
                        message: "Campo obrigatorio!",
                      },
                    ]}
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                {isBrasil ? (
                  <Form.Item
                    label="Cidade"
                    name="city"
                    initialValue={prospectData.city}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Cidade"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .indexOf(
                            input
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                          ) >= 0
                      }
                      disabled={!formValues?.uf || isDisabled}
                      onChange={(value) =>
                        setFormValues({ ...formValues, city: value })
                      }
                    >
                      {CityOptions || []}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Cidade"
                    name="city"
                    initialValue={prospectData.city}
                    onChange={(e) =>
                      setFormValues({ ...formValues, city: e.target.value })
                    }
                  >
                    <Input disabled={isDisabled} />
                  </Form.Item>
                )}
              </Col>

              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="Rua"
                  initialValue={prospectData.street}
                  name="street"
                  onChange={(e) =>
                    setFormValues({ ...formValues, street: e.target.value })
                  }
                >
                  <Input disabled={isDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={6} flex="center">
                <Form.Item
                  label="Número"
                  initialValue={prospectData.street_number}
                  name="street_number"
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      street_number: e.target.value,
                    })
                  }
                >
                  <Input disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={6} flex="center">
                <Form.Item
                  label="Complemento"
                  initialValue={prospectData.street_complement}
                  name="street_complement"
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      street_complement: e.target.value,
                    })
                  }
                >
                  <Input disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="Serviço interessado"
                  name="service"
                  initialValue={prospectData.interesting_service_id}
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Selecionar Serviços"
                    optionFilterProp="children"
                    disabled={fullServiceListLoading || isDisabled}
                    onChange={(value) =>
                      setFormValues({
                        ...formValues,
                        interesting_service_id: value,
                      })
                    }
                    loading={fullServiceListLoading}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {optionsService}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="Cidade de nascimento"
                  name="city_of_birth"
                  initialValue={prospectData.city_of_birth}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Cidade de nascimento"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        ) >= 0
                    }
                    disabled={!formValues?.uf || isDisabled}
                    onChange={(value) =>
                      setFormValues({ ...formValues, city_of_birth: value })
                    }
                  >
                    {CityBirthOptions || []}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  label="Estado de nascimento"
                  name="uf_of_birth"
                  initialValue={prospectData.uf_of_birth}
                >
                  <Select
                    showSearch
                    disabled={isDisabled}
                    allowClear
                    placeholder="Estado de nascimento"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .indexOf(
                          input
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        ) >= 0
                    }
                    onChange={(value) =>
                      setFormValues({
                        ...formValues,
                        uf_of_birth: value,
                        city: "",
                      })
                    }
                  >
                    {UFOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  name="where_meet_us"
                  initialValue={prospectData.where_meet_us}
                  label="Onde nos conheceu"
                  onBlur={(e) =>
                    setFormValues({
                      ...formValues,
                      where_meet_us: e.target.value,
                    })
                  }
                >
                  <Input
                    placeholder="Onde nos conheceu"
                    showCount
                    maxLength={50}
                    name="where_meet_us"
                    label="where_meet_us"
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} flex="center">
                <Form.Item
                  name="goal"
                  label="Objetivo"
                  initialValue={prospectData.goal}
                  onBlur={(e) =>
                    setFormValues({ ...formValues, goal: e.target.value })
                  }
                >
                  <Input
                    placeholder="Objetivo"
                    showCount
                    maxLength={50}
                    name="goal"
                    label="goal"
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
              <Col xs={24} sm={8} flex="center">
                <Form.Item
                  label="Status"
                  name="status"
                  initialValue={prospectData.status}
                >
                  <Select
                    allowClear
                    placeholder="Status"
                    optionFilterProp="children"
                    disabled={isDisabled}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) =>
                      setFormValues({ ...formValues, status: value })
                    }
                  >
                    <Select.Option key="NEW">Novo</Select.Option>
                    <Select.Option key="NEG">Em Negociação</Select.Option>
                    <Select.Option key="WAI">Aguardando Retorno</Select.Option>
                    <Select.Option key="CLO">Encerrado</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} flex="center">
                <Form.Item
                  label="Responsável"
                  initialValue={prospectData.assignee_id}
                  name="responsavel"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Selecionar responsável"
                    optionFilterProp="children"
                    disabled={responsableListLoading || isDisabled}
                    onChange={(value) =>
                      setFormValues({ ...formValues, assignee_id: value })
                    }
                    loading={responsableListLoading}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {optionsResponsable}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} flex="center">
                <Form.Item
                  label="Data da Notificação"
                  name="notify_date"
                  initialValue={prospectData.notify_date}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      notify_date: e.target.value,
                    })
                  }
                >
                  <Input type="date" disabled={isDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="state_bon"
              label="Board of Nursing"
              initialValue={prospectData.state_bon}
              onBlur={(e) =>
                setFormValues({ ...formValues, state_bon: e.target.value })
              }
            >
              <Input
                placeholder="Board of Nursing"
                showCount
                maxLength={50}
                disabled={isDisabled}
                name="state_bon"
                label="state_bon"
              />
            </Form.Item>

            <Space
              size={"middle"}
              align="center"
              className={styled.buttonWrapper}
            >
              {isDisabled && (
                <Button
                  type="primary"
                  className="hp-bg-black-100 hp-border-color-black-100 hp-hover-bg-black-80 hp-hover-border-color-black-80"
                  htmlType="submit"
                  loading={loadingVerify}
                  onClick={() =>
                    getVerify("verifyCustomer", { cpf: formValues.cpf })
                  }
                >
                  Converter em cliente
                </Button>
              )}
              <Button
                type={isDisabled ? "primary" : "danger"}
                className={
                  isDisabled
                    ? "hp-hover-bg-primary-1"
                    : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                      " hp-btn-outline"
                }
                onClick={editHandle}
              >
                {isDisabled ? "Editar" : "Cancelar"}
              </Button>

              {!isDisabled && (
                <Button
                  type="primary"
                  className="hp-btn-outline hp-hover-bg-primary-1"
                  htmlType="submit"
                  onClick={submit}
                >
                  Confirmar Edição
                </Button>
              )}
            </Space>
          </Form>
          {prospectLogStatus.success && (
            <>
              <List
                className="comment-list"
                header={`${
                  prospectLogData?.length > 0 ? prospectLogData?.length : "Sem"
                } comentário${prospectLogData?.length === 1 ? "" : "s"}`}
                itemLayout="horizontal"
                dataSource={prospectLogData}
                renderItem={(item) => (
                  <li className={styled.flex}>
                    <Comment
                      avatar={
                        <i
                          className="ri-user-3-line"
                          style={{ fontSize: "30px" }}
                        />
                      }
                      content={
                        isChanging === item.id ? (
                          <>
                            <Space>
                              <Input
                                placeholder={item.name}
                                onChange={(e) => setNewName(e.target.value)}
                              />
                              <a
                                className={styled.icon}
                                onClick={() =>
                                  changeName(
                                    item.prospect_customer_id,
                                    item.id,
                                    newName
                                  )
                                }
                              >
                                <i
                                  className="ri-check-fill"
                                  title="Editar histórico"
                                />
                              </a>
                            </Space>
                          </>
                        ) : (
                          item.description
                        )
                      }
                      datetime={moment(item.created_at)
                        .tz("America/Sao_Paulo")
                        .format("DD/MM/YYYY - HH:mm")}
                    />
                    <div>
                      {hasPermission(user, Permissions.cadastrar_prospeccoes) &&
                        isChanging !== item.id && (
                          <a
                            className={styled.icon}
                            onClick={() => setIsChanging(item.id)}
                          >
                            <i
                              className="ri-edit-fill"
                              title="Editar histórico"
                            />
                          </a>
                        )}

                      {hasPermission(user, Permissions.remover_prospeccoes) && (
                        <Popconfirm
                          title={
                            "Tem certeza que deseja deletar o histórico: " +
                            item.description +
                            "?"
                          }
                          onConfirm={() =>
                            deleteProspect(item.prospect_customer_id, item.id)
                          }
                          okText="Confirmar"
                          cancelText="Cancelar"
                        >
                          <a className={styled.icon}>
                            <i
                              className={
                                styled.iconDelete + " ri-delete-bin-fill"
                              }
                              title="Deletar histórico"
                            />
                          </a>
                        </Popconfirm>
                      )}
                    </div>
                  </li>
                )}
              />
              <Comment
                content={
                  <Form form={form}>
                    <Form.Item name="textArea" onBlur={handleChange}>
                      <TextArea rows={4} placeholder="Adicionar comentário" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        loading={prospectAddLogLoading}
                        onClick={submitLog}
                        type="primary"
                      >
                        Adicionar comentário
                      </Button>
                    </Form.Item>
                  </Form>
                }
              />
            </>
          )}
        </Card>
      )}
      <Modal open={isModalVisible} footer={null} width="50%">
        <h4>Esse CPF já está cadastrado.</h4>
        <br />
        <Button onClick={() => setModalVisible(false)}>Ok</Button>
        <br />
      </Modal>
    </Wrapper>
  );
};

export default ViewProspect;
