import { useState, useEffect } from "react";
import styled from "./documents.module.scss";
import { useRest } from "../../services/http";
import { Table, Button } from "antd";
import { useAuth } from "../../providers/auth";
import moment from "moment-timezone";
import { RiDownload2Fill } from "react-icons/ri";

const Documents = () => {
  const { user } = useAuth();
  const { get, loading, data, status } = useRest();

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 15,
  });

  useEffect(() => {
    get("documents", { page: tablePagination.current });
  }, [tablePagination?.current]);

  useEffect(() => {
    if (data && status.success) {
      setTablePagination({
        ...tablePagination,
        pageSize: data?.per_page,
        total: data?.total,
      });
    }
  }, [data]);

  const downloadFile = async (filePath) => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}${filePath}`, {
      headers: {
        Authorization: `${"Bearer " + user?.access_token}`,
      },
    });
    const response = await data.blob();
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);

    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = filePath.replace("attachment/", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleTableChange = (pagination) => {
    setTablePagination({ ...tablePagination, current: pagination.current });
  };

  const columns = [
    {
      title: "Documento",
      key: "name",
      render: (record) => <p>{record.alias || record.name}</p>,
    },
    {
      title: "Download",
      dataIndex: "path",
      key: "path",
      render: (record) => (
        <Button
          icon={<RiDownload2Fill className="remix-icon" />}
          onClick={() => downloadFile(record)}
        />
      ),
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      key: "created_at",
      render: (record) =>
        moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY - HH:mm"),
    },
    {
      title: "Atualizado em",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (record) =>
        moment(record).tz("America/Sao_Paulo").format("DD/MM/YYYY - HH:mm"),
    },
  ];

  return (
    <Table
      className={styled.table}
      columns={columns}
      rowKey={(record) => record?.id}
      dataSource={data?.data}
      pagination={tablePagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      size={15}
    />
  );
};

export default Documents;
