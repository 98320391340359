const Buffer = require("buffer/").Buffer;

export const checkFirstLogin = (token) => {
  const response = JSON.parse(
    Buffer.from(token.split(".")[1], "base64").toString()
  );

  return response.first_login;
};

export const getName = (token) => {
  const response = JSON.parse(
    Buffer.from(token.split(".")[1], "base64").toString()
  );

  return response.username;
};

export const getRole = (token) => {
  const response = JSON.parse(
    Buffer.from(token.split(".")[1], "base64").toString()
  );

  return response.role;
};

export const getPermissions = (token) => {
  const response = JSON.parse(
    Buffer.from(token.split(".")[1], "base64").toString()
  );

  return response.permissions;
};

export const getCustomerID = (token) => {
  const response = JSON.parse(
    Buffer.from(token.split(".")[1], "base64").toString()
  );
  return response?.customer_id;
};

export const getUserID = (token) => {
  const response = JSON.parse(
    Buffer.from(token.split(".")[1], "base64").toString()
  );
  return response?.sub;
};
