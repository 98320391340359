export const showEmbededHTML = () =>
  setTimeout(() => {
    const parentElement = document.getElementsByClassName(
      "calendly-inline-widget"
    );

    if (parentElement) {
      Array.prototype.slice.call(parentElement).forEach((element) => {
        console.log(element);
        const url = element.getAttribute("data-url");
        // eslint-disable-next-line no-undef
        Calendly.initInlineWidget({
          url,
          parentElement: element,
          prefill: {},
          utm: {},
        });
      });
    }
  }, 2000);
