import { Form, Input, Row, Col, Select } from "antd";
import { BrazilianStates } from "../../../enums/brazilianStates";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import { validate } from "gerador-validador-cpf";
import pt from "react-phone-input-2/lang/pt.json";
import "react-phone-input-2/lib/style.css";
import styled from "./styled.module.scss";

const PersonalData = ({ setData, setEmpty, defaultData, convertData }) => {
  const [formValues, setFormValues] = useState({
    birthdate: defaultData?.birthdate || convertData?.birthdate || "",
    username: defaultData?.username || convertData?.username || "",
    password: defaultData?.password || convertData?.password || "",
    name: defaultData?.name || convertData?.name || "",
    phone_number: defaultData?.phone_number || convertData?.phone_number || "",
    email: defaultData?.email || convertData?.email || "",
    uf_of_birth: defaultData?.uf_of_birth || convertData?.uf_of_birth || "",
    city_of_birth:
      defaultData?.city_of_birth || convertData?.city_of_birth || "",
    rg: defaultData?.rg || convertData?.rg || "",
    issued_by: defaultData?.issued_by || convertData?.issued_by || "",
    cpf: defaultData?.cpf || convertData?.cpf || "",
    marital_status:
      defaultData?.marital_status || convertData?.marital_status || "",
  });

  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues.uf_of_birth
  );

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));

  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  useEffect(() => {
    const hasEmpty =
      !formValues.birthdate ||
      !formValues.username ||
      !formValues.password ||
      !formValues.name ||
      !formValues.phone_number ||
      !formValues.email ||
      !formValues.uf_of_birth ||
      !formValues.city_of_birth ||
      !formValues.rg ||
      !formValues.cpf ||
      !formValues.marital_status;
    setEmpty(hasEmpty);
    setData(formValues);
  }, [formValues, setData, setEmpty]);

  useEffect(() => {
    if (convertData && Object.keys(defaultData).length < 1) {
      setFormValues(convertData);
      setEmpty(true);
    }
  }, []);

  return (
    <>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Nome"
            name="name"
            initialValue={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
              {
                pattern: /^([\W\w]{2,})+\s+([\W\w\s]{1,})+$/i,
                message:
                  "Informe o nome e o sobrenome sem caracteres especiais.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Email"
            name="email"
            initialValue={formValues.email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
              {
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "Email inválido!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Usuário"
            name="username"
            initialValue={formValues.username}
            onChange={(e) =>
              setFormValues({ ...formValues, username: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Senha de acesso"
            name="password"
            initialValue={formValues.password}
            onChange={(e) =>
              setFormValues({ ...formValues, password: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
              {
                required: true,
                message: "A senha deve conter ao menos 6 caracteres!",
                min: 6,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Telefone"
            name="phone_number"
            initialValue={formValues.phone_number}
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <PhoneInput
              country={"br"}
              value={formValues.phone_number}
              onChange={(value) =>
                setFormValues({ ...formValues, phone_number: "+" + value })
              }
              localization={pt}
              enableSearch
              inputClass={styled.phoneNumber}
              defaultErrorMessage="Campo obrigatório!"
              isValid={(inputNumber) => (inputNumber.length > 0 ? true : false)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Data de nascimento"
            name="birthdate"
            initialValue={formValues.birthdate}
            onChange={(e) =>
              setFormValues({ ...formValues, birthdate: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input type="date" value={formValues.birthdate} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="RG"
            name="rg"
            initialValue={formValues.rg}
            onChange={(e) =>
              setFormValues({ ...formValues, rg: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input value={formValues.rg} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Órgão Emissor / UF"
            name="org-rg"
            initialValue={formValues.issued_by}
            onChange={(e) =>
              setFormValues({ ...formValues, issued_by: e.target.value })
            }
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="CPF"
            name="cpf"
            initialValue={formValues.cpf}
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
              {
                validator(_, value) {
                  if (!validate(value)) {
                    return Promise.reject(new Error("CPF inválido!"));
                  }
                },
              },
            ]}
          >
            <InputMask
              mask="999.999.999-99"
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cpf: e.target.value.replace(/[^\d]/g, ""),
                })
              }
            >
              <Input />
            </InputMask>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Estado Civil"
            name="marital_status"
            initialValue={formValues.marital_status}
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Estado Civil"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) =>
                setFormValues({
                  ...formValues,
                  marital_status: value,
                })
              }
            >
              <Select.Option key="SNG">Solteiro(a)</Select.Option>
              <Select.Option key="MAR">Casado(a)</Select.Option>
              <Select.Option key="SEP">Separado(a)</Select.Option>
              <Select.Option key="DIV">Divorciado(a)</Select.Option>
              <Select.Option key="WID">Viúvo(a)</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="UF de Nascimento"
            name="uf"
            initialValue={formValues.uf_of_birth}
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="UF de Nascimento"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(
                    input
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) >= 0
              }
              onChange={(value) =>
                setFormValues({
                  ...formValues,
                  uf_of_birth: value,
                  city_of_birth: "",
                })
              }
            >
              {UFOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Cidade de Nascimento"
            name="city"
            initialValue={formValues.city_of_birth}
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Cidade de Nascimento"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(
                    input
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) >= 0
              }
              disabled={!formValues.uf_of_birth}
              onChange={(value) =>
                setFormValues({ ...formValues, city_of_birth: value })
              }
            >
              {CityOptions || []}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PersonalData;
