import { Form, Input, Row, Col, Select, Button, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import cepPromise from "cep-promise";
import { useState, useEffect, useMemo } from "react";
import { Country } from "../../../enums/country";
import { BrazilianStates } from "../../../enums/brazilianStates";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";

const Address = ({ setData, defaultData, isEditable }) => {
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState({
    country: defaultData.country,
    cep: defaultData.cep,
    uf: defaultData.uf,
    city: defaultData.city,
    street: defaultData.street,
    street_complement: defaultData.street_complement,
    street_number: defaultData.street_number,
  });

  useEffect(() => {
    setData(formValues);
  }, [formValues, setData]);

  const getAddress = async () => {
    try {
      const address = await cepPromise(formValues.cep);
      setFormValues({
        ...formValues,
        uf: address.state,
        city: address.city,
        street: address.street,
      });
      form.setFieldValue("uf", address.state);
      form.setFieldValue("city", address.city);
      form.setFieldValue("street", address.street);
    } catch (e) {
      message.error("CEP não encontrado!");
    }
  };

  const CountryOptions = Country.map((item) => (
    <Select.Option key={item.nome_pais}>{item.nome_pais}</Select.Option>
  ));

  const UFIndex = BrazilianStates.states.findIndex(
    (x) => x.uf === formValues.uf
  );

  const UFOptions = BrazilianStates.states.map((item) => (
    <Select.Option key={item.uf}>{item.name}</Select.Option>
  ));

  const CityOptions = BrazilianStates?.states[UFIndex]?.citys.map((item) => (
    <Select.Option key={item}>{item}</Select.Option>
  ));

  const isBrasil = useMemo(() => {
    return formValues.country === "Brasil";
  }, [formValues]);

  return (
    <Form form={form} layout="vertical" disabled={!isEditable}>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={6}>
          <Form.Item
            label="País"
            name="country"
            initialValue={formValues.country}
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="País"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormValues({ ...formValues, country: value })
              }
            >
              {CountryOptions}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={6}>
          <Form.Item
            label={isBrasil ? "CEP" : "Código Postal"}
            name="cep"
            initialValue={formValues.cep}
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <InputMask
              mask={isBrasil ? "99999-999" : null}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cep: e.target.value.replace(/[^\d]/g, ""),
                })
              }
              value={formValues.cep}
            >
              <Input />
            </InputMask>

            {isBrasil && (
              <Button
                style={{ position: "absolute", right: 0, top: 0 }}
                type="primary"
                icon={<SearchOutlined />}
                onClick={getAddress}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Estado"
              name="uf"
              initialValue={formValues.uf}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Estado"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                onChange={(value) =>
                  setFormValues({
                    ...formValues,
                    uf: value,
                    city: "",
                  })
                }
              >
                {UFOptions}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Estado"
              name="uf"
              initialValue={formValues.uf}
              onChange={(e) =>
                setFormValues({ ...formValues, uf: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={6}>
          {isBrasil ? (
            <Form.Item
              label="Cidade"
              name="city"
              initialValue={formValues.city}
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Cidade"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .indexOf(
                      input
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    ) >= 0
                }
                disabled={!formValues.uf}
                onChange={(value) =>
                  setFormValues({ ...formValues, city: value })
                }
              >
                {CityOptions || []}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Cidade"
              name="city"
              initialValue={formValues.city}
              onChange={(e) =>
                setFormValues({ ...formValues, city: e.target.value })
              }
              rules={[
                {
                  required: true,
                  message: "Campo obrigatorio!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Rua"
            name="street"
            initialValue={formValues.street}
            onChange={(e) =>
              setFormValues({ ...formValues, street: e.target.value })
            }
            rules={[
              {
                required: true,
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={4}>
          <Form.Item
            label="Número"
            name="street_number"
            initialValue={formValues.street_number}
            onChange={(e) =>
              setFormValues({ ...formValues, street_number: e.target.value })
            }
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Complemento"
            name="street_complement"
            initialValue={formValues.street_complement}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                street_complement: e.target.value,
              })
            }
            rules={[
              {
                message: "Campo obrigatorio!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Address;
