/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Form, Input, Button, Alert, message } from "antd";
import { useEffect } from "react";
import { useRest } from "../../services/http";
import { useNavigate } from "react-router-dom";

export default function ConfirmationToken() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    post: postConfirmationToken,
    status,
    loading,
    message: confirmationTokenMessage,
    data,
  } = useRest();

  const submit = async () => {
    const isFormValid = await form.validateFields();

    if (isFormValid) {
      const tokenData = {
        token: form.getFieldValue("token"),
      };
      await postConfirmationToken("confirmationToken", tokenData);
    }
  };

  useEffect(() => {
    if (status?.success) {
      message.success(confirmationTokenMessage);
      navigate("/reset-password", {
        state: { username: data?.username },
      });
    }
  }, [status]);

  return (
    <Row gutter={[32, 0]} className="hp-authentication-page">
      <Col lg={32} span={24} className="hp-py-sm-0 hp-py-md-64">
        <Row className="hp-h-100" align="middle" justify="center">
          <Col
            xxl={8}
            xl={8}
            lg={8}
            md={12}
            sm={24}
            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
          >
            {status?.error && (
              <Alert message={confirmationTokenMessage} type="error" closable />
            )}
            <br />
            <h1 className="hp-mb-sm-0">Confirmar token</h1>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              className="hp-mt-sm-16 hp-mt-32"
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label="Token:"
                className="hp-mb-16"
                name="token"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input id="token" />
              </Form.Item>

              <Form.Item className="hp-mt-16 hp-mb-8">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={submit}
                  loading={loading}
                >
                  Confirmar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
