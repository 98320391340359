/* eslint-disable react-hooks/exhaustive-deps */
import Wrapper from "../../components/layout";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
  Input,
  Form,
  Button,
  Row,
  Col,
  message,
  Switch,
  Space,
  Card,
  Select,
} from "antd";
import styled from "./styled.module.scss";
import { useState, useEffect } from "react";
import { useRest } from "../../services/http";
import { useParams, useNavigate } from "react-router-dom";
import { Trash } from "iconsax-react";
const { TextArea } = Input;

export const ViewService = () => {
  const [form] = Form.useForm();
  const [steps, setSteps] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableSteps, setDisableSteps] = useState(true);
  const [optionsInstruction, setOptionsInstruction] = useState([]);
  const { id } = useParams();
  const { get: viewService, data: viewData } = useRest();
  const {
    put: updateService,
    status,
    loading: updateLoading,
    data: updateData,
    message: updateMessage,
  } = useRest();
  const navigate = useNavigate();

  const { get: fullInstructionsList, data: fullInstructionsListData } =
    useRest();

  const [formValues, setFormValues] = useState({ is_commercialized: true });

  useEffect(() => {
    fullInstructionsList("fullInstructionsList");
  }, []);

  useEffect(() => {
    let instructionArr = fullInstructionsListData?.map((item) => (
      <Select.Option key={item.id}>{item.title}</Select.Option>
    ));

    setOptionsInstruction(instructionArr);
  }, [fullInstructionsListData]);

  useEffect(() => {
    const newOrderWithID = steps.map((item, index) => ({
      ...item,
      sort: index,
      id: item?.id,
    }));
    if (JSON.stringify(steps) !== JSON.stringify(newOrderWithID)) {
      setSteps(newOrderWithID);
    }
  }, [setSteps, steps]);

  useEffect(() => {
    form.getFieldsValue(["step"]);
  }, [form]);

  const sortSteps = (steps, oldIndex, newIndex) => {
    const newOrder = arrayMoveImmutable(steps, oldIndex, newIndex);
    const newOrderWithID = newOrder.map((item, index) => ({
      ...item,
      sort: index,
    }));
    return newOrderWithID;
  };

  const removeStep = (index) => {
    const filtered = steps.filter((_, i) => i !== index);
    setSteps(filtered);
  };

  const pushStep = () => {
    setSteps([
      ...steps,
      {
        title: form.getFieldValue("step"),
        description: form.getFieldValue("step_description") || "",
        instructions: form.getFieldValue("instructions") || [],
      },
    ]);
    form.resetFields(["step", "step_description", "instructions"]);
    setDisableSteps(true);
  };
  const editHandle = () => {
    setIsDisabled(!isDisabled);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSteps(sortSteps(steps, oldIndex, newIndex));
  };

  const getInstructionTitle = (id) => {
    return fullInstructionsListData?.find((item) => item.id == id).title;
  };

  const SortableItem = SortableElement(({ value }) => (
    <div className={styled.wrapSteps}>
      <span>
        Etapa {value.sort + 1} - {value.title}
      </span>
      <span>{value.description}</span>
      {value.instructions && (
        <ul>
          {value.instructions.map((value, index) => (
            <li key={index}>{getInstructionTitle(value)}</li>
          ))}
        </ul>
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div style={{ position: "relative" }}>
        {isDisabled && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 1,
            }}
          />
        )}
        <div style={{ cursor: "grab", margin: "20px 0" }}>
          {items.map((value, index) => (
            <div key={`item-${index}`} className={styled.wrapper}>
              <SortableItem index={index} value={value} />
              {!isDisabled && (
                <a onClick={() => removeStep(index)}>
                  <Trash color="red" />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  });

  const submit = () => {
    updateService("updateService", { id: id }, { ...formValues, steps: steps });
    setDisableSteps(true);
    setIsDisabled(true);
  };

  useEffect(() => {
    if (status?.success) {
      message.success(updateMessage);
      navigate("/service-list");
    }
    if (status?.error) {
      if (updateData?.steps?.length > 0) {
        message.error(updateData.steps[0]);
      } else {
        message.error(updateMessage);
      }
    }
  }, [status, form, updateMessage]);

  useEffect(() => {
    viewService("viewService", { id: id });
  }, [id]);

  useEffect(() => {
    if (viewData) {
      form.setFieldValue("title", viewData?.title);
      form.setFieldValue(
        "is_commercialized",
        Boolean(viewData?.is_commercialized)
      );
      setFormValues({
        title: viewData?.title,
        is_commercialized: Boolean(viewData?.is_commercialized),
      });
      setSteps(viewData.steps);
    }
  }, [viewData]);

  return (
    <Wrapper>
      <Card>
        <Form form={form} layout="vertical">
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="title"
                label="Nome do serviço"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome do serviço"
                  showCount
                  maxLength={50}
                  name="title"
                  label="title"
                  disabled={isDisabled}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Switch
                  checked={formValues?.is_commercialized}
                  disabled={isDisabled}
                  onChange={(checked) =>
                    setFormValues({
                      ...formValues,
                      is_commercialized: checked,
                    })
                  }
                />{" "}
                Pode ser comercializado?
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item
                name="step"
                label="Nome da etapa"
                disabled={isDisabled}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value && steps === []) {
                        setDisableSteps(true);
                        return Promise.reject();
                      }
                      setDisableSteps(false);
                      return Promise.resolve();
                    },
                  }),
                  {
                    required: true,
                    message: "Campo obrigatorio!",
                  },
                ]}
              >
                <Input
                  placeholder="Nome da etapa"
                  showCount
                  maxLength={150}
                  name="step"
                  label="step"
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="step_description" label="Descrição da etapa">
                <TextArea
                  showCount
                  name="step_description"
                  maxLength={100}
                  placeholder="Descrição da etapa"
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Button
                block
                type="primary"
                className={styled.stepButton}
                disabled={disableSteps}
                onClick={pushStep}
              >
                Adicionar etapa
              </Button>
            </Col>
          </Row>
          <SortableList
            items={steps}
            onSortEnd={onSortEnd}
            disableAutoscroll={true}
          />
          <Row justify="space-between" gutter={{ sm: 16, xs: 10 }}>
            <Col xs={24} sm={24} flex="center">
              <Form.Item name="instructions" label="Instruções">
                <Select
                  name="instructions"
                  mode="multiple"
                  showSearch
                  allowClear
                  placeholder="Selecionar Instruções"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {optionsInstruction}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Space size={"middle"} align="center" className={styled.buttonWrapper}>
          <Button
            type={isDisabled ? "primary" : "danger"}
            className={
              isDisabled
                ? "hp-hover-bg-primary-1"
                : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                  " hp-btn-outline"
            }
            onClick={editHandle}
          >
            {isDisabled ? "Editar" : "Cancelar"}
          </Button>

          {!isDisabled && (
            <Button
              type="primary"
              className="hp-btn-outline hp-hover-bg-primary-1"
              loading={updateLoading}
              htmlType="submit"
              onClick={submit}
            >
              Confirmar Edição
            </Button>
          )}
        </Space>
      </Card>
    </Wrapper>
  );
};
export default ViewService;
