/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Wrapper from "../../components/layout";
import {
  Form,
  Input,
  Button,
  Space,
  message,
  Spin,
  Row,
  Col,
  Checkbox,
  Card,
} from "antd";
import styled from "./styled.module.scss";
import { useRest } from "../../services/http";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { showEmbededHTML } from "../../util/ckeditor";

const ViewInstructions = () => {
  const {
    get: viewInstructions,
    loading: viewLoading,
    data: viewData,
  } = useRest();

  const {
    put: updateInstruction,
    loading: updateLoading,
    message: updateMessage,
    status: updateStatus,
    data: updateData,
  } = useRest();

  const [form] = Form.useForm();
  const { id } = useParams();
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({});

  const submit = () => {
    updateInstruction("updateInstruction", { id: id }, formValues);
  };

  const editHandle = () => {
    setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    viewInstructions("viewInstructions", { id: id });
  }, [id]);

  useEffect(() => {
    setFormValues({
      title: viewData?.title,
      description: viewData?.description,
      enable: viewData?.enable,
    });
  }, [viewData]);

  useEffect(() => {
    if (updateStatus?.success) {
      message.success(updateMessage);
      setIsDisabled(true);
      navigate("/instructions-list");
    }
    if (updateStatus?.error) {
      updateData
        ? message.error(Object.values(updateData)[0])
        : message.error(updateMessage);
    }
    showEmbededHTML();
  }, [updateStatus]);

  return (
    <Wrapper>
      <Card>
        {viewLoading ? (
          <Row type="flex" align="middle" justify="center">
            <Col>
              <Spin />
            </Col>
          </Row>
        ) : (
          <Form form={form} onFinish={submit} layout="vertical">
            <Form.Item
              name="description"
              className={styled.textArea}
              label="Titulo da instrução"
              initialValue={viewData?.title}
            >
              <Input
                placeholder="Titulo da instrução"
                showCount
                maxLength={50}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    title: e.target.value,
                  })
                }
                name="title"
                label="title"
                id="title"
                disabled={isDisabled}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox
                defaultChecked={viewData?.enable}
                disabled={isDisabled}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    enable: e.target.checked,
                  })
                }
              >
                Instrução ativa?
              </Checkbox>
            </Form.Item>
            <div className={isDisabled ? "editorDisabled" : ""}>
              <CKEditor
                editor={Editor}
                data={viewData?.description}
                disabled={isDisabled}
                onChange={(events, editor) => {
                  const data = editor.getData();
                  setFormValues({
                    ...formValues,
                    description: data,
                  });
                }}
              />
            </div>
            <Space
              size={"middle"}
              align="center"
              className={styled.buttonWrapper}
            >
              <Button
                type={isDisabled ? "primary" : "danger"}
                className={
                  isDisabled
                    ? "hp-hover-bg-primary-1"
                    : "hp-text-color-danger-1 hp-border-color-danger-1 hp-hover-bg-danger-1" +
                      " hp-btn-outline"
                }
                onClick={editHandle}
              >
                {isDisabled ? "Editar" : "Cancelar"}
              </Button>

              {!isDisabled && (
                <Button
                  type="primary"
                  className="hp-btn-outline hp-hover-bg-primary-1"
                  loading={updateLoading}
                  htmlType="submit"
                >
                  Confirmar Edição
                </Button>
              )}
            </Space>
          </Form>
        )}
      </Card>
    </Wrapper>
  );
};

export default ViewInstructions;
