/* eslint-disable react-hooks/exhaustive-deps */
import { Button, message, Steps, Alert, Form, Card } from "antd";
import { useState, useEffect, useMemo } from "react";
import Wrapper from "../../components/layout";
import PersonalDataPage from "./personalData";
import Address from "./address";
import ComplementData from "./complementData";
import Education from "./education";
import Coren from "./coren";
import Security from "./security";
import Employer from "./employer";
import { useNavigate } from "react-router-dom";
import { useRest } from "../../services/http";
import { useLocation } from "react-router-dom";

const { Step } = Steps;

const CreateClient = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [personalData, setPersonalData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [complementData, setComplementData] = useState({});
  const [corenData, setCorenData] = useState({});
  const [securityData, setSecurityData] = useState({});
  const [educationData, setEducationData] = useState({});
  const [employerData, setEmployerData] = useState({});
  const [customerId, setCustomerId] = useState({});
  const [createdClient, setCreatedClient] = useState(false);
  const [hasEmpty, setHasEmpty] = useState(false);
  const [form] = Form.useForm();
  const { state } = useLocation();

  const {
    post: createCustomer,
    data: customerData,
    status: customerStatus,
    loading,
    message: customerMessage,
  } = useRest();

  useEffect(() => {
    if (customerStatus.success) {
      message.success("Cliente cadastrado com sucesso!");
      setCustomerId({ customer_id: customerData.id });
      setCreatedClient(true);
      setCurrent((current) => current + 1);
    } else if (customerStatus.error) {
      message.error("Ocorreu um erro. Tente novamente.");
    }
  }, [customerStatus]);

  const createClient = () => {
    createCustomer("createCustomer", {
      ...personalData,
      ...addressData,
      ...complementData,
      ...corenData,
    });
  };

  const steps = [
    {
      title: "Dados Pessoais",
      content: (
        <PersonalDataPage
          setData={(value) => setPersonalData(value)}
          convertData={state}
          defaultData={personalData}
          setEmpty={(hasEmpty) => setHasEmpty(hasEmpty)}
          form={form}
        />
      ),
    },
    {
      title: "Endereço",
      content: (
        <Address
          setData={(value) => setAddressData(value)}
          defaultData={addressData}
          convertData={state}
          setEmpty={(hasEmpty) => setHasEmpty(hasEmpty)}
          form={form}
        />
      ),
    },
    {
      title: "Dados Complementares",
      content: (
        <ComplementData
          setData={(value) => setComplementData(value)}
          defaultData={complementData}
          setEmpty={(hasEmpty) => setHasEmpty(hasEmpty)}
          form={form}
        />
      ),
    },
    {
      title: "Dados Licença COREN",
      content: (
        <Coren
          setData={(value) => setCorenData(value)}
          setEmpty={(hasEmpty) => setHasEmpty(hasEmpty)}
          defaultData={corenData}
        />
      ),
    },
    {
      title: "Dados de Segurança",
      content: (
        <Security
          form={form}
          customerId={customerId}
          setData={(value) => setSecurityData(value)}
          defaultData={securityData}
        />
      ),
    },
    {
      title: "Dados de Ensino",
      content: (
        <Education
          form={form}
          customerId={customerId}
          setData={(value) => setEducationData(value)}
          defaultData={educationData}
        />
      ),
    },
    {
      title: "Dados de Empregador",
      content: (
        <Employer
          form={form}
          customerId={customerId}
          setData={(value) => setEmployerData(value)}
          defaultData={employerData}
        />
      ),
    },
  ];

  const next = (e) => {
    const errors = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (errors.length === 0 && !hasEmpty) {
      if (current === 1 && !createdClient) {
        createClient();
      } else {
        e.preventDefault();
        setCurrent((current) => current + 1);
      }
    } else {
      message.error("Preencha todos os campos corretamente!");
    }
  };

  const prev = () => {
    const errors = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (errors.length === 0 && !hasEmpty) {
      setCurrent((current) => current - 1);
    } else {
      message.error("Preencha todos os campos corretamente!");
    }
  };

  const onChange = (value) => {
    const errors = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (!createdClient) {
      if (errors.length > 0 || hasEmpty) {
        message.error("Preencha todos os campos corretamente!");
      } else if (value > 1) {
        message.error(
          "Crie o cliente no passo 2 antes de selecionar essa opção."
        );
      } else {
        setCurrent(value);
      }
    } else {
      setCurrent(value);
    }
  };

  const finish = () => {
    navigate("/client-list");
  };

  const errorMessage = useMemo(() => {
    if (customerData) {
      if (Object.values(customerData)?.length > 0) {
        return Object.values(customerData).map((data) => (
          <>
            {data}
            <br />
          </>
        ));
      } else {
        return customerMessage;
      }
    }
  }, [customerData]);

  return (
    <Wrapper>
      <Steps
        current={current}
        onChange={onChange}
        labelPlacement="vertical"
        size="small"
        style={{ marginBottom: "25px" }}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <Card>
        <Form
          name="create-client"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
        >
          <div className="steps-content">
            {customerStatus.error && (
              <Alert
                message="Dados incorretos:"
                description={errorMessage}
                type="error"
              />
            )}
            {steps[current].content}
          </div>
          <div className="steps-action">
            {current > 0 && !loading && (
              <Button
                type="secondary"
                style={{
                  margin: "0 8px 0 0",
                }}
                onClick={() => prev()}
              >
                Anterior
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={(e) => next(e)}
                htmlType="submit"
                loading={loading}
                className="hp-bg-black-100 hp-border-color-black-100 hp-hover-bg-black-80 hp-hover-border-color-black-80"
              >
                {current === 1 && !createdClient ? "Criar cliente" : "Próximo"}
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="secondary" onClick={finish}>
                Finalizar
              </Button>
            )}
          </div>
        </Form>
      </Card>
    </Wrapper>
  );
};

export default CreateClient;
